import React, { useState, useMemo } from "react";
import {
  Box,
  TextField,
  Chip,
  Checkbox,
  List,
  ListItem,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { useTheme } from "@mui/styles";

const YearFilter = ({ onChange, colors, selectedItems }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [showPastEvents, setShowPastEvents] = useState(false);
  const theme = useTheme();

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggle = (value) => {
    if (!showPastEvents) {
      return [];
    }
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    if (onChange) {
      onChange(newChecked);
    } else console.log(newChecked);
  };

  const years = useMemo(() => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let year = currentYear; year >= 2015; year--) {
      years.push(year.toString());
    }
    return years;
  }, []);

  const filteredData = useMemo(() => {
    return years.filter((year) =>
      year.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [years, searchTerm]);

  const handleDeleteChip = (chipToDelete) => () => {
    handleToggle(chipToDelete);
  };

  const handleSwitchChange = (event) => {
    setShowPastEvents(event.target.checked);
  };

  return (
    <Box sx={{ width: { xs: "100%", sm: 400 }, maxWidth: "100%" }}>
      <FormControlLabel
        control={
          <Switch
            checked={showPastEvents}
            onChange={handleSwitchChange}
            color="secondary"
            sx={{ m: 1, mt: 0, mb: 0 }}
          />
        }
        label="Show Past Events"
      />
      {showPastEvents && (
        <TextField
          fullWidth
          id="search-field"
          label="Search Years"
          variant="outlined"
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ bgcolor: "#fff", mt: 2 }}
        />
      )}
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          my: 2,
        }}
      >
        {selectedItems.map((item) => (
          <Chip
            size="small"
            key={item}
            label={item}
            onDelete={handleDeleteChip(item)}
            sx={{
              background: colors?.secondary
                ? colors.secondary
                : theme?.primaryColor,
              color:
                colors?.secondary && colors?.primary ? colors.primary : "#fff",
              "& .MuiChip-deleteIcon": {
                color:
                  colors?.secondary && colors?.primary
                    ? colors.primary
                    : "#fff",
              },
            }}
          />
        ))}
      </Box>
      {showPastEvents && (
        <List dense sx={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
          {filteredData.map((year) => (
            <ListItem key={year} dense>
              <Checkbox
                edge="start"
                sx={{ padding: "2px 0px" }}
                checked={selectedItems.indexOf(year) !== -1}
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": `checkbox-list-label-${year}`,
                }}
                onChange={() => handleToggle(year)}
              />
              {`>= ${year}`}
            </ListItem>
          ))}
        </List>
      )}
    </Box>
  );
};

export default YearFilter;
