import { Routes, Route, Navigate } from "react-router-dom";

// import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import { UserAuthContextProvider } from "./context/UserAuthContext";
import { AppContextProvider } from "./appContext";
import Signup from "./components/auth/Signup";
import { ThemeProvider } from "@mui/styles";
import theme from "./theme";
// import Dashboard from "./components/Core/Dashboard";
import Login from "./components/auth/Login";
import "./App.css"; // Make sure this import is here
import EventsLayout from "./components/Core/Events";
import EventForm from "./components/Core/Events/AddEditEvent";
import VenuesLayout from "./components/Core/Venues";
import VenueForm from "./components/Core/Venues/AddEditVenue";
import BandsLayout from "./components/Core/Bands";
import BandForm from "./components/Core/Bands/AddEditBand";
// import Dashboard from "./components/Core/Dashboard/dash";
import Dashboard from "./components/Core/Dashboard";
import PagesLayout from "./components/Core/Pages";
import PageForm from "./components/Core/Pages/AddEditPage";

function App() {
  const currentUser = localStorage.getItem("currentUser");
  return (
    <ThemeProvider theme={theme}>
      <div>
        <UserAuthContextProvider>
          <AppContextProvider>
            <Routes>
              <Route
                path="/dashboard"
                element={
                  <ProtectedRoute role="manager">
                    <Dashboard />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editEvent"
                element={
                  <ProtectedRoute role="manager">
                    <EventForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editVenue"
                element={
                  <ProtectedRoute role="manager">
                    <VenueForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editBand"
                element={
                  <ProtectedRoute role="manager">
                    <BandForm />
                  </ProtectedRoute>
                }
              />

              <Route
                path="/events"
                element={
                  <ProtectedRoute role="manager">
                    <div style={{ padding: 30 }}>
                      <EventsLayout />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/venues"
                element={
                  <ProtectedRoute role="manager">
                    <div style={{ padding: 30 }}>
                      <VenuesLayout />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/bands"
                element={
                  <ProtectedRoute role="manager">
                    <div style={{ padding: 30 }}>
                      <BandsLayout />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/pages"
                element={
                  <ProtectedRoute role="manager">
                    <div style={{ padding: 30 }}>
                      <PagesLayout />
                    </div>
                  </ProtectedRoute>
                }
              />
              <Route
                path="/editPage"
                element={
                  <ProtectedRoute role="manager">
                    <PageForm />
                  </ProtectedRoute>
                }
              />
              <Route
                path="/"
                element={currentUser ? <Navigate to="/dashboard" /> : <Login />}
              />
              <Route
                path="/signup"
                element={
                  <div>
                    <Signup />
                  </div>
                }
              />
            </Routes>
          </AppContextProvider>
        </UserAuthContextProvider>
      </div>
    </ThemeProvider>
  );
}

export default App;
