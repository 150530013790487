import React, { useCallback, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Grid,
  IconButton,
  Skeleton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate, useLocation } from "react-router-dom";
import SocialMediaLinks from "../../SocialMediaLinks";
import { EventImage } from "../Events/EventAccordion";
import useIsSmallScreen from "../../../utils/useIsSmallScreen";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDeleteBand, useFetchEventsByBandId } from "../../../hooks";
import CustomAlertDialog from "../../Elements/AlertDialogue";

const BandCard = ({ data, loading }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const isSmallScreen = useIsSmallScreen();
  const { deleteBand, loading: deleting } = useDeleteBand();

  const [itemToDelete, setItemToDelete] = useState(false);
  const { events, loading: eventsLoading } = useFetchEventsByBandId(
    itemToDelete?.id
  );
  const handleViewDetails = useCallback((e, id) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("bandId", id);
    navigate(`${location.pathname}?${searchParams.toString()}`);
    // handle view details
  }, []);

  const handleEdit = useCallback((e, id) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("bandId", id);
    navigate(`/editBand?${searchParams.toString()}`);
  }, []);

  const handleDelete = useCallback((e, bandToDelete) => {
    e.stopPropagation();
    setItemToDelete(bandToDelete);
  }, []);

  const handledeleteBand = () => {
    deleteBand(itemToDelete?.id).then(() => {
      handleCloseDialog();
    });
  };
  const handleCloseDialog = () => {
    setItemToDelete(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
      {loading ? (
        <Box width={"100%"}>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {data.map((band, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  height: 160,
                  position: "relative",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "space-between",
                  cursor: "pointer",
                  transition: "transform 0.2s, box-shadow 0.2s",
                  "&:hover": {
                    transform: "scale(1.05)",
                    boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.2)",
                  },
                }}
                onClick={(e) => handleViewDetails(e, band.id)}
              >
                <IconButton
                  aria-label="details"
                  onClick={(e) => handleViewDetails(e, band.id)}
                  sx={{
                    position: "absolute",
                    top: 8,
                    right: 8,
                    zIndex: 1, // Ensures the button is always clickable
                  }}
                >
                  <VisibilityIcon sx={{ color: "#2a892a" }} />
                </IconButton>
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 40,
                    right: 8,
                    zIndex: 1, // Ensures the button is always clickable
                  }}
                  aria-label="edit"
                  onClick={(e) => handleEdit(e, band.id)}
                >
                  <EditIcon sx={{ color: "#307fc1" }} />
                </IconButton>
                <IconButton
                  sx={{
                    position: "absolute",
                    top: 72,
                    right: 8,
                    zIndex: 1, // Ensures the button is always clickable
                  }}
                  aria-label="delete"
                  onClick={(e) => handleDelete(e, band)}
                >
                  <DeleteIcon sx={{ color: "#e34e4e" }} />
                </IconButton>

                <CardContent sx={{ display: "flex", alignItems: "center" }}>
                  <EventImage
                    key={band.id + band.imageUrl}
                    styles={{
                      height: isSmallScreen ? "100px" : "120px",
                      width: isSmallScreen ? "100px" : "120px",
                      marginRight: 16,
                    }}
                    imgUrl={
                      band?.imageUrl
                        ? band.imageUrl
                        : "/images/random/defaultband.jpg"
                    }
                  />
                  <Box>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {band?.bandname || "Band Title"}
                    </Typography>
                    <Typography variant="body2" color="textSecondary">
                      {band?.alias?.length
                        ? `aka: ${band.alias.join(", ")}`
                        : ""}
                    </Typography>
                  </Box>
                </CardContent>
                {band.socialMediaLinks?.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: -5,
                    }}
                  >
                    <SocialMediaLinks
                      size={"48"}
                      containerSize={30}
                      links={band.socialMediaLinks}
                      onClick={(e) => e.stopPropagation()} // Ensure social media links remain clickable
                    />
                  </Box>
                )}
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <CustomAlertDialog
        title={"Delete Band Confirmation"}
        description={
          eventsLoading
            ? `Fetching Band Events...`
            : events?.length > 0
            ? `Can't delete the band, band contains events. Click details to view Events`
            : `Are you sure you want to delete the Band ${itemToDelete?.bandname}`
        }
        proceed={handledeleteBand}
        open={Boolean(itemToDelete?.id)}
        handleClose={handleCloseDialog}
        disabled={!eventsLoading && events?.length > 0}
        isLoading={deleting || eventsLoading}
      />
    </Box>
  );
};

export default BandCard;
