import { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
  getCountFromServer,
} from "firebase/firestore";
import { db } from "../firebase-config.";
import { useAppState } from "../appContext";
import { useNotification } from "../components/Notification";
import { query, where, orderBy, limit, startAfter } from "firebase/firestore";

// Custom hook to fetch all bands from Firestore

const bandsCache = {};

const generateBandsCacheKey = (searchTerms, skip, type, take) => {
  const searchKey = searchTerms?.join("-") || "all";
  return `${searchKey}-${skip}-${type}-${take}`;
};

export const useFetchBandsWithSearch = (
  take = 20,
  skip = 0,
  searchTerms = [],
  type = "accordion"
) => {
  const [bands, setBands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [totalItems, setTotalItems] = useState(0);
  const [lastDoc, setLastDoc] = useState(null); // Track the last document for pagination
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBands = async () => {
      try {
        setLoading(true);
        const cacheKey = generateBandsCacheKey(searchTerms, skip, type, take);
        // Check if results for this query are already cached
        if (bandsCache[cacheKey]) {
          const cachedResult = bandsCache[cacheKey];
          setBands(cachedResult.bands);
          setTotalItems(cachedResult.totalItems);
          setLastDoc(cachedResult.lastDoc);
          setLoading(false);
          return;
        }

        let bandsQuery = collection(db, "bands");

        if (searchTerms.length > 0) {
          bandsQuery = query(
            bandsQuery,
            where("searchTerms", "array-contains-any", searchTerms)
          );
        }
        const countSnapshot = await getCountFromServer(bandsQuery);

        const totalCount = countSnapshot.data().count;

        bandsQuery = query(bandsQuery, orderBy("bandname", "asc"), limit(take));

        if (skip > 0 && lastDoc) {
          bandsQuery = query(bandsQuery, startAfter(lastDoc));
        }

        const querySnapshot = await getDocs(bandsQuery);
        const fetchedBands = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];

        // Cache the result
        bandsCache[cacheKey] = {
          bands: fetchedBands,
          totalItems: totalCount,
          lastDoc: lastVisible,
        };

        setBands(fetchedBands);
        setTotalItems(totalCount);
        setLastDoc(lastVisible);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching bands:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    fetchBands();
  }, [take, skip, searchTerms, type]);

  return { bands, loading, totalItems, error };
};
let lastCachedBands = null; // Store the last cached data globally

export const useFetchBands = (refresh = null, enableApi = true) => {
  const [bands, setBands] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBands = async () => {
      const cacheKey = generateBandsCacheKey([], 0, 0, refresh);

      // If refresh is null and enableApi is false, return the last cached data.
      if (refresh === null && !enableApi) {
        if (lastCachedBands) {
          setBands(lastCachedBands);
          setLoading(false);
        } else {
          setLoading(false); // No data to show, but stop loading.
        }
        return;
      }

      // If API is enabled or refresh is dynamic, fetch new data.
      setLoading(true);

      // If cached data exists for this key, use the cached version.
      if (bandsCache[cacheKey] && refresh === null) {
        const cachedResult = bandsCache[cacheKey];
        setBands(cachedResult.bands);
        setLoading(false);
        return;
      }

      try {
        // Fetch new data from the database
        let bandsQuery = collection(db, "bands");
        bandsQuery = query(bandsQuery, orderBy("title_lowercase", "asc"));

        const querySnapshot = await getDocs(bandsQuery);
        const fetchedBands = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Cache the fetched bands using the generated cache key
        bandsCache[cacheKey] = {
          bands: fetchedBands,
        };

        // Also update the last cached data
        lastCachedBands = fetchedBands;

        setBands(fetchedBands);
        setLoading(false);
      } catch (err) {
        console.error("Error fetching bands:", err);
        setError(err.message);
        setLoading(false);
      }
    };

    // Fetch new data only if enableApi is true or refresh is dynamic.
    fetchBands();
  }, [refresh, enableApi]);

  return { bands, loading, error };
};

export const useFetchBandById = (bandId) => {
  const [band, setBand] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchBand = async () => {
      try {
        if (!bandId) return;

        setLoading(true);
        const bandDocRef = doc(db, "bands", bandId);
        const bandDoc = await getDoc(bandDocRef);

        if (bandDoc.exists()) {
          setBand({
            id: bandDoc.id,
            ...bandDoc.data(),
          });
        } else {
          setError("Band not found");
        }
      } catch (err) {
        setError(`Error fetching band: ${err.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchBand();
  }, [bandId]);

  return { band, loading, error };
};
// Custom hook to add a new band to Firestore
export const useAddBand = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const addBand = async (bandData) => {
    try {
      setLoading(true);
      await setDoc(doc(db, "bands", `${bandData.id}`), bandData);
      showSuccessMessage("Band added successfully");
    } catch (error) {
      showWarningMessage("Error adding band:", error);
    } finally {
      setLoading(false);
    }
  };

  return { addBand, loading };
};

// Custom hook to update an existing band in Firestore
export const useUpdateBand = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const updateBand = async (bandId, bandData) => {
    try {
      setLoading(true);
      const bandRef = doc(db, "bands", bandId + "");
      await updateDoc(bandRef, bandData);
      showSuccessMessage("Band updated successfully");
    } catch (error) {
      console.log("Band updated unsuccessfully", error, bandId);
      showWarningMessage("Error updating band:", error);
    } finally {
      setLoading(false);
    }
  };

  return { updateBand, loading };
};

// Custom hook to delete an band from Firestore
export const useDeleteBand = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();
  const [state, dispatch] = useAppState();

  const deleteBand = async (bandId) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "bands", bandId));

      showSuccessMessage("Band Deleted successfully");
    } catch (error) {
      showWarningMessage("Error Deleting Band:", error);
    } finally {
      setLoading(false);
    }
  };

  return { deleteBand, loading };
};
export const useFetchBandByInternalLink = () => {
  const [band, setBand] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchBand = async (internallink) => {
    if (!internallink) {
      setError("Internal link is required");
      return;
    }

    try {
      setLoading(true);
      const bandsRef = collection(db, "bands");
      const q = query(
        bandsRef,
        where("internallink", "==", `/${internallink.toLowerCase()}/`)
      );
      const querySnapshot = await getDocs(q);

      if (!querySnapshot.empty) {
        const bandDoc = querySnapshot.docs[0]; // Assuming internallink is unique
        setBand({
          id: bandDoc.id,
          ...bandDoc.data(),
        });
        return { id: bandDoc.id, ...bandDoc.data() };
      } else {
        setError("Band not found");
      }
    } catch (err) {
      setError(`Error fetching band: ${err.message}`);
    } finally {
      setLoading(false);
    }
  };

  return { band, loading, error, fetchBand };
};
