import React from "react";
import { makeStyles } from "@mui/styles";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  Paper,
  IconButton,
  Collapse,
  Box,
  Typography,
  TableSortLabel,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { useAppState } from "../../../appContext";
import { Link } from "react-router-dom";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
import { getAttributeValue } from "../../../utils/utility";

const useStyles = makeStyles({
  table: {
    // minWidth: 650,
  },
  tableContainer: {
    borderRadius: 15,
    // margin: "10px 10px",
    // maxWidth: 950,
  },
  tableHeaderCell: {
    fontWeight: "bold",
    backgroundColor: "#00000024;",
    // color: "#FFF",
  },
  arrow: {
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  expandableIconCell: {
    width: "10%", // Set a minimal width percentage
    padding: "0 16px", // Adjust padding as needed
  },
  highlightRow: {
    backgroundColor: "#f5f5f5", // Choose a light color for highlighting
  },
});

function Row(props) {
  const { row, columns, expandable, eventsExpandedAttributes } = props;
  const [state] = useAppState();
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();
  // const getAttributeValue = (att, row) => {
  //   const value = row[att?.name];
  //   switch (att?.component?.type) {
  //     case "dateTime":
  //       return value ? moment(value).format("llll") : "-";
  //     case "object": {
  //       const item = state?.[att.state].find((i) => i.id === value);
  //       return item?.id ? (
  //         <>
  //           <Link
  //             to={`/${att?.state}?${att?.component?.queryParam}=${item?.id}`}
  //           >
  //             {item?.[att?.component?.contextProp]}
  //           </Link>
  //         </>
  //       ) : (
  //         "-"
  //       );
  //     }

  //     case "array":
  //       return (
  //         <>
  //           {value.length
  //             ? value?.map((v, i) => {
  //                 const item = state?.[att.state].find((i) => i.id === v);
  //                 return (
  //                   <>
  //                     <Link
  //                       key={"arrayItem" + i + item.id}
  //                       to={`/${att?.state}?${att?.component?.queryParam}=${item.id}`}
  //                     >
  //                       {item?.[att?.component?.contextProp]}
  //                     </Link>
  //                     <br></br>
  //                   </>
  //                 );
  //               })
  //             : "-"}
  //         </>
  //       );

  //     default:
  //       return value;
  //   }
  // };
  // const getAttributeValue = (att, row) => {
  //   let value = row[att.name];
  //   if (att.name === "bands") {
  //     return (
  //       <>
  //         {value.length
  //           ? value?.map((v, i) => {
  //               return (
  //                 <>
  //                   <Link key={"bandname" + i + v.id} to={`?${"band"}=${v.id}`}>
  //                     {v?.[att.contextProp]}
  //                   </Link>
  //                 </>
  //               );
  //             })
  //           : "-"}
  //       </>
  //     );
  //   } else if (att.name === "venue") {
  //     return value ? (
  //       <>
  //         <Link to={`?${"venue"}=${value.id}`}>{value[att.contextProp]}</Link>
  //       </>
  //     ) : (
  //       "-"
  //     );
  //   }
  //  else {
  //     return value;
  //   }
  // };
  return (
    <React.Fragment>
      <TableRow
        className={open ? classes.highlightRow : ""}
        sx={{
          "& > *": { borderBottom: "unset" },
          "&:last-child td, &:last-child th": { border: 0 },
        }}
      >
        {expandable && (
          <TableCell className={classes.expandableIconCell}>
            <IconButton
              className={classes.arrow}
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        )}
        {columns.map((column, index) => (
          <TableCell key={index}>
            {column.component
              ? getAttributeValue(column, row, state)
              : row[column.name]}
          </TableCell>
        ))}
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Details
              </Typography>
              <Box
                sx={{
                  display: { xs: "block", md: "flex" },
                  justifyContent: "space-between",
                }}
              >
                {eventsExpandedAttributes.map((section, i) => {
                  return (
                    <Box
                      width={
                        eventsExpandedAttributes.length === 1
                          ? "100%"
                          : eventsExpandedAttributes.length === 2
                          ? "50%"
                          : "33%"
                      }
                      key={"events_detail_secion" + i}
                    >
                      {section.map((el, index) => (
                        <Box key={index + row[el?.name]}>
                          <Attribute key={index + "details_att_events"}>
                            <AttributeLabel> {el.label}</AttributeLabel>
                            <AttributeValue>
                              {el?.component
                                ? getAttributeValue(el, row, state)
                                : row[el?.name]}
                            </AttributeValue>
                          </Attribute>
                        </Box>
                      ))}
                    </Box>
                  );
                })}
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
export default function GenericTable({
  data,
  columns,
  expandable,
  pagination,
  loading,
  eventsExpandedAttributes = [],
  ...rest
}) {
  const classes = useStyles();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [orderDirection, setOrderDirection] = React.useState("asc");
  const [valueToOrderBy, setValueToOrderBy] = React.useState("");

  const handleRequestSort = (columnName) => {
    const isAscending =
      valueToOrderBy === columnName && orderDirection === "asc";
    setOrderDirection(isAscending ? "desc" : "asc");
    setValueToOrderBy(columnName);
  };

  const createSortHandler = (columnName) => (event) => {
    handleRequestSort(columnName);
  };

  const sortedData = React.useMemo(() => {
    if (!valueToOrderBy) return data;

    const comparator = (a, b) => {
      if (a[valueToOrderBy] < b[valueToOrderBy]) {
        return -1;
      }
      if (a[valueToOrderBy] > b[valueToOrderBy]) {
        return 1;
      }
      return 0;
    };

    return [...data].sort(
      orderDirection === "desc" ? (a, b) => comparator(b, a) : comparator
    );
  }, [data, orderDirection, valueToOrderBy]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <TableContainer component={Paper} className={classes.tableContainer}>
      <Table className={classes.table} aria-label="collapsible table" {...rest}>
        <TableHead>
          <TableRow>
            {expandable && (
              <TableCell
                className={`${classes.tableHeaderCell} ${classes.expandableIconCell}`}
              />
            )}
            {columns.map((column, index) => (
              <TableCell
                key={index}
                className={classes.tableHeaderCell}
                align={column.align || "left"}
                sortDirection={
                  valueToOrderBy === column.name ? orderDirection : false
                }
              >
                {column.sortable ? (
                  <TableSortLabel
                    sx={{
                      // Styles for both active and non-active states
                      "&.MuiTableSortLabel-root": {
                        color: "#7d7c7c", // Non-active text color
                        "& svg": {
                          fill: "#7d7c7c", // Non-active icon color
                        },
                      },
                      // Styles for active state
                      "&.Mui-active": {
                        color: "#7d7c7c", // Active text color
                        "& svg": {
                          fill: "#307fc1", // Active icon color
                        },
                      },
                      // Styles for hover state
                      "&:hover": {
                        color: "#7d7c7c", // Hover text color
                        "& svg": {
                          fill: "#307fc1", // Hover icon color
                        },
                      },
                    }}
                    active={valueToOrderBy === column.name}
                    direction={
                      valueToOrderBy === column.name ? orderDirection : "asc"
                    }
                    onClick={createSortHandler(column.name)}
                  >
                    {column.label}
                  </TableSortLabel>
                ) : (
                  column.label
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        {loading ? (
          <>
            {Array.from(new Array(rowsPerPage)).map((_, index) => (
              <TableRow key={index}>
                {expandable && <TableCell />}{" "}
                {/* For expandable icon if exists */}
                {Array(columns.length)
                  .fill()
                  .map((_, cellIndex) => (
                    <TableCell key={cellIndex} align="center">
                      <Skeleton variant="text" width="100%" height={24} />
                    </TableCell>
                  ))}
              </TableRow>
            ))}
          </>
        ) : (
          <TableBody>
            {pagination
              ? sortedData
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <Row
                      key={index + Math.random() * 10 + row.id}
                      row={row}
                      columns={columns}
                      classes={classes}
                      expandable={expandable}
                      eventsExpandedAttributes={eventsExpandedAttributes}
                    />
                  ))
              : sortedData.map((row, index) => (
                  <Row
                    key={index + Math.random() * 10 + row.id}
                    row={row}
                    columns={columns}
                    classes={classes}
                    expandable={expandable}
                    eventsExpandedAttributes={eventsExpandedAttributes}
                  />
                ))}
          </TableBody>
        )}
      </Table>
      {pagination && (
        <TablePagination
          rowsPerPageOptions={[5, 10, 15]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </TableContainer>
  );
}
