import React from "react";
import {
  Box,
  CardHeader as MuiCardHeader,
  IconButton,
  styled,
  Typography,
} from "@mui/material/";
import makeStyles from "@mui/styles/makeStyles";
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: ({ headerColor, colorCode, style }) => ({
    display: "flex",
    // height: "60px",
    padding: "0 10px 0 20px",
    borderBottom: headerColor && "1px solid #efeff1",
    borderLeft: colorCode && `3px solid ${colorCode}`,
    backgroundColor: headerColor ? headerColor : "#ffffff",
    ...style,
  }),
  title: ({ fontColor }) => ({
    color: fontColor,
    "& button": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  }),
  avatar: {
    width: "18px",
    height: "18px",
  },
  content: {
    width: "100%",
  },
}));

const ColorCode = styled(({ colorCode, ...rest }) => <div {...rest} />)(
  ({ colorCode }) => ({
    width: "8px",
    height: "8px",
    backgroundColor: colorCode,
    borderRadius: "50%",
    marginLeft: "8px",
  })
);

const CardHeader = (props) => {
  const {
    cardTitle,
    handleExpandClick,
    expanded,
    collapsible,
    headerColor,
    fontColor,
    icon,
    colorCode,
    style,
    isTitleJsx,
  } = props;
  const classes = useStyles({
    headerColor,
    fontColor,
    icon,
    colorCode,
    style,
  });
  return (
    <>
      <MuiCardHeader
        avatar={icon}
        sx={{ borderBottom: "1px solid #efeff1" }}
        title={
          <Box display="flex" justifyContent="space-between">
            <Box display="flex" alignItems="center" width="100%">
              {isTitleJsx ? (
                cardTitle
              ) : (
                <Typography
                  style={{
                    maxWidth: "90%",
                    fontSize: "18px",
                    fontWeight: "normal",
                    fontStretch: "normal",
                    fontStyle: "normal",
                    lineHeight: "normal",
                    letterSpacing: "normal",
                    color: "#000",
                  }}
                >
                  {cardTitle}
                </Typography>
              )}
              <ColorCode colorCode={colorCode} />
            </Box>
            {collapsible && (
              <IconButton
                onClick={handleExpandClick}
                aria-expanded={expanded}
                aria-label="show more"
                disableFocusRipple
                disableRipple
                size="large"
              >
                {expanded ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            )}
          </Box>
        }
        titleTypographyProps={{ variant: "h2" }}
        classes={classes}
      ></MuiCardHeader>
    </>
  );
};

export default CardHeader;
