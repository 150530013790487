import React, { useState } from "react";

function SocialMediaLinks({ links, size = "96", containerSize }) {
  // Initialize an object to track errors for each link by index
  const [imageErrors, setImageErrors] = useState({});

  function ensureFullURL(url) {
    if (!/^https?:\/\//i.test(url)) {
      return `https://www.${url}`;
    }
    return url;
  }

  function handleError(index) {
    setImageErrors((prevErrors) => ({
      ...prevErrors,
      [index]: true,
    }));
  }

  return (
    <div className="social-media-links">
      {links
        ?.filter((l) => l.linkname !== "image")
        ?.map((link, index) => (
          <a
            key={index}
            href={ensureFullURL(link.url)}
            target="_blank"
            rel="noopener noreferrer"
          >
            {!imageErrors[index] ? (
              <img
                style={{ height: `${containerSize ? containerSize : size}px` }}
                src={`/logos/${link.linkname
                  .toLowerCase()
                  .replace(/\s+/g, "-")}-${size}.png`}
                alt={link.linkname}
                onError={() => handleError(index)}
              />
            ) : (
              <span>{link.linkname}</span>
            )}
          </a>
        ))}
    </div>
  );
}

export default SocialMediaLinks;
