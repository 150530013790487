import React, { useState, useRef, useEffect } from "react";
import { Box, Button } from "@mui/material";
import Cropper from "cropperjs";
import "cropperjs/dist/cropper.min.css";

const ImageCropper = ({
  onCrop,
  src,
  aspectRatio = 4 / 1,
  height = "300px",
  width = "100%",
}) => {
  const [imageSrc, setImageSrc] = useState(src);
  const [croppedImage, setCroppedImage] = useState(""); // New state to hold the cropped image
  const imageRef = useRef(null);
  const cropperRef = useRef(null);
  const fileInputRef = useRef(null);
  const [file, setFile] = useState(null); // To hold the actual image file

  const onSelectFile = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
        setCroppedImage(""); // Reset the cropped image
        setFile(file); // Store the file in state
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (src) setImageSrc(src);
  }, [src]);
  const handleCrop = () => {
    if (cropperRef.current && file) {
      const canvas = cropperRef.current.getCroppedCanvas();
      const dataUrl = canvas.toDataURL();
      setCroppedImage(dataUrl); // Save the cropped image
      canvas.toBlob((blob) => {
        const croppedFile = new File([blob], file.name, { type: file.type });

        // Call onCrop with both the file and data URL
        onCrop(croppedFile, dataUrl);
      });
      //   onCrop(dataUrl);
      cropperRef.current.destroy(); // Destroy the Cropper instance
      cropperRef.current = null;
      setImageSrc(""); // Clear the original image src to remove the cropper UI
    }
  };

  const handleEdit = () => {
    fileInputRef.current.click();
  };

  // Initialize Cropper when an image is selected
  React.useEffect(() => {
    if (imageSrc && !croppedImage && imageRef.current) {
      cropperRef.current = new Cropper(imageRef.current, {
        aspectRatio,
        viewMode: 1,
        dragMode: "move",
        autoCropArea: 1,
        movable: true,
        cropBoxMovable: true,
        cropBoxResizable: true,
      });
    }
  }, [imageSrc, croppedImage]);

  return (
    <Box
      sx={{
        width,
        height,
        position: "relative",
        backgroundColor: "#EEF0F4",
      }}
    >
      <input
        ref={fileInputRef}
        accept="image/*"
        type="file"
        onChange={onSelectFile}
        hidden
        id="icon-button-file"
      />
      {imageSrc && !croppedImage && (
        <img
          ref={imageRef}
          src={imageSrc}
          alt="Crop area"
          style={{
            // width: "100%",
            height: "100%",
            maxHeight: "400px",
            // maxWidth: "400px",
            objectFit: "cover",
            marginLeft: "40%",
          }}
        />
      )}
      {!imageSrc && !croppedImage && (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            cursor: "pointer",
            backgroundColor: "rgba(0, 0, 0, 0.3)",
          }}
          onClick={() => fileInputRef.current.click()}
        >
          Click to select an image
        </Box>
      )}
      {croppedImage && (
        <img
          src={croppedImage}
          alt="Cropped"
          style={{
            // width: "100%",
            height: "100%",
            maxHeight: "400px",
            // maxWidth: "400px",
            objectFit: "cover",
            marginLeft: "40%",
          }}
        />
      )}
      <Box sx={{ display: "flex", gap: 2, justifyContent: "end", mt: 2 }}>
        {!croppedImage && (
          <Button variant="contained" color="primary" onClick={handleCrop}>
            Crop
          </Button>
        )}
        {croppedImage && (
          <Button variant="contained" color="secondary" onClick={handleEdit}>
            Edit
          </Button>
        )}
        {imageSrc && (
          <Button
            variant="contained"
            style={{ background: "red" }}
            onClick={() => {
              setImageSrc("");
              setFile(null);
              setCroppedImage(null);
            }}
          >
            Remove
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ImageCropper;
