import { Box, Button, Paper } from "@mui/material";

import GenericTable from "../../Elements/GenericTable";
import { useFetchEventsByDate } from "../../../hooks";

const eventsAttributes = [
  {
    label: "Title",
    name: "title",
    sortable: true,
  },
  {
    label: "Event Date",
    name: "main_date",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
];
const eventsExpandedAttributes = [
  [
    {
      label: "Event Type",
      name: "eventtype",
    },
    {
      label: "Description",
      name: "eventdescription",
      component: {
        type: "html",
      },
    },
    { label: "id", name: "id" },
  ],
  [
    {
      label: "Bands",
      name: "bands",
      state: "bands",
      component: {
        type: "array",
        contextProp: "bandname",
        queryParam: "bandId",
      },
    },
    {
      label: "Venue Name",
      name: "venue_name",
      // state: "venues",
      component: {
        type: "link",
        contextProp: "venue_name",
        queryParam: "venueId",
        identifier: "venue",
        path: "venues",
      },
    },
  ],
];
const Dashboard = () => {
  const { events: futureEvents, loading: fLoading } = useFetchEventsByDate(
    true,
    10
  );
  const { events: pastEvents, loading: pLoading } = useFetchEventsByDate(
    false,
    10
  );

  return (
    <>
      <Box
        sx={{
          display: { xs: "block", md: "flex" },
          gap: 20,
          padding: 3,
          justifyContent: "center",
        }}
      >
        <Paper
          sx={{
            border: "1px solid gray",
            textAlign: "center",
            width: { xs: "100%", md: "40%" },
            height: "fit-content",
          }}
        >
          <h2>Upcoming Events</h2>

          <GenericTable
            data={futureEvents}
            expandable={true}
            columns={eventsAttributes}
            size={"xsall"}
            loading={fLoading}
            eventsExpandedAttributes={eventsExpandedAttributes}
          />
        </Paper>
        <Paper
          sx={{
            marginTop: { xs: "15px", md: 0 },
            border: "1px solid gray",
            textAlign: "center",
            width: { xs: "100%", md: "40%" },
            height: "fit-content",
          }}
        >
          <h2>Past Events</h2>

          <GenericTable
            data={pastEvents}
            expandable={true}
            columns={eventsAttributes}
            size={"xsall"}
            loading={pLoading}
            eventsExpandedAttributes={eventsExpandedAttributes}
          />
        </Paper>
      </Box>
    </>
  );
};

export default Dashboard;
