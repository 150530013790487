import { useLocation, useNavigate } from "react-router";
import CustomDrawer from "../../Elements/CustomDrawer";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Skeleton,
  Typography,
} from "@mui/material";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
import moment from "moment";
import { Link } from "react-router-dom";
import { EventImage } from "./EventAccordion";
import MyGoogleMap from "../../Elements/GoogleMap";
import { useCallback, useState } from "react";
import { useDeleteEvent, useFetchEventById } from "../../../hooks";
import CustomAlertDialog from "../../Elements/AlertDialogue";
import { getAttributeValue } from "../../../utils/utility";
import { useAppState } from "../../../appContext";
import { useFetchVenueById } from "../../../hooks/venueHooks";

const attributes = [
  {
    label: "Title",
    name: "title",
    sortable: true,
  },
  {
    label: "Description",
    name: "eventdescription",
    component: {
      type: "html",
    },
  },
  {
    label: "Event Date",
    name: "main_date",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
  {
    label: "Modified Date",
    name: "modifieddate",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
  {
    label: "Event Type",
    name: "eventtype",
  },
  {
    label: "Tickets URL",
    name: "ticket_url",
  },
  // {
  //   label: "Bands",
  //   name: "bands",
  //   component: {
  //     type: "array",
  //     contextProp: "bandname",
  //     queryParam: "band",
  //   },
  // },
  // {
  //   label: "Venue",
  //   name: "venue",
  //   component: {
  //     type: "object",
  //     contextProp: "venue_name",
  //     queryParam: "venue",
  //   },
  // },
];
const BandComponent = ({ band }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
          gap: 2,
          alignItems: "center",
        }}
      >
        <EventImage imgUrl={band?.imageUrl} styles={{ height: "140px" }} />
        <Box>
          <h3>{band?.bandname}</h3>
        </Box>
      </Box>
    </>
  );
};
const VenueComponent = ({ venueId }) => {
  const { venue, loading } = useFetchVenueById(venueId);
  if (loading) {
    return (
      <Box>
        <Skeleton height={100} animation="wave" />
        <Skeleton height={100} animation="wave" />
        <Skeleton height={100} animation="wave" />
      </Box>
    );
  }
  return (
    <>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          gap: 2,
          flexDirection: "column",
          //   alignItems: "center",
        }}
      >
        <Box>
          <h3>{venue?.venue_name}</h3>
        </Box>
        <Box sx={{ width: "100%" }}>
          <MyGoogleMap markers={[venue]} />
        </Box>
      </Box>
    </>
  );
};
export const EventDetails = ({
  onDrawerClose,
  setRefreshTrigger = () => {},
}) => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("eventId");
  const { event, loading } = useFetchEventById(eventId);
  const { deleteEvent, loading: deleting } = useDeleteEvent();

  const [itemToDelete, setItemToDelete] = useState(false);

  const navigate = useNavigate();

  const [state] = useAppState();

  const getBands = (bands) => {
    return state?.bands.filter((b) => bands?.includes(b.id));
  };

  const handleEdit = useCallback((e, id) => {
    e.stopPropagation();
    searchParams.set("eventId", id);
    navigate(`/editEvent?${searchParams.toString()}`);
  }, []);
  const handleDelete = useCallback((e, eventToDelete) => {
    e.stopPropagation();
    setItemToDelete(eventToDelete);
  }, []);

  const handleDeleteEvent = () => {
    deleteEvent(itemToDelete?.id).then(() => {
      handleCloseDialog();
    });
  };
  const handleCloseDialog = () => {
    setItemToDelete(null);
    searchParams.delete("eventId");

    // Navigate to the same path but with the modified search parameters
    navigate(`${location.pathname}?${searchParams.toString()}`);
    setRefreshTrigger();
  };
  return (
    <>
      <CustomDrawer
        open={eventId}
        loading={loading}
        onClose={() => {
          onDrawerClose();
        }}
        title={event?.title}
      >
        {event ? (
          <>
            <Box
              sx={{
                padding: 2,
                flex: 1,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mb: 5,
                  height: "250px",
                }}
              >
                <Box
                  component="img"
                  src={
                    event.imageUrl
                      ? event.imageUrl
                      : "https://via.placeholder.com/1100x250?text=Cover+Photo"
                  }
                  alt="Cover Photo"
                  sx={{
                    objectFit: "cover",
                  }}
                />
              </Box>
              <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>
                Event Details
              </h4>
              {attributes.map((attribute, idx) => (
                <Attribute key={idx + attribute.name}>
                  <AttributeLabel>{attribute.label}</AttributeLabel>
                  <AttributeValue>
                    {attribute?.component
                      ? getAttributeValue(attribute, event, state)
                      : event[attribute.name]}
                  </AttributeValue>
                </Attribute>
              ))}
              <Divider sx={{ my: 2 }} />
              <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>
                Bands
              </h4>
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {event?.bands?.length ? (
                  event?.bands?.map((b) => (
                    <Box key={b.id} sx={{ flex: "1 0 calc(50% - 16px)" }}>
                      {" "}
                      {/* Assuming each band has an id for the key, and subtracting the gap from the 50% width */}
                      <BandComponent band={b} />
                    </Box>
                  ))
                ) : (
                  <Typography paragraph>No Bands Found</Typography>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
              <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>
                Venue
              </h4>
              {event?.venue ? (
                <VenueComponent venueId={event?.venue} />
              ) : (
                <Typography paragraph>No Venue Found</Typography>
              )}
              <Divider sx={{ my: 5 }} />
              {/* <Divider sx={{ my: 2 }} />
              <Typography paragraph>
                Duis aute irure dolor in reprehenderit in voluptate velit esse.
              </Typography> */}
            </Box>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-evenly",
                gap: 5,
                padding: 2,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={(e) => handleDelete(e, event)}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => handleEdit(e, event?.id)}
              >
                Edit
              </Button>
            </Box>
          </>
        ) : (
          <CircularProgress />
        )}
      </CustomDrawer>
      <CustomAlertDialog
        title={"Delete Event Confirmation"}
        description={`Are you sure you want to delete the event ${itemToDelete?.title}`}
        proceed={handleDeleteEvent}
        open={Boolean(itemToDelete?.id)}
        isLoading={deleting}
        handleClose={handleCloseDialog}
      />
    </>
  );
};
