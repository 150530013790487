import React, { useCallback, useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Pagination,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Avatar,
  CardActions,
  IconButton,
  Skeleton,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
import moment from "moment";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CustomAlertDialog from "../../Elements/AlertDialogue";
import { useAppState } from "../../../appContext";
import { useDeleteVenue } from "../../../hooks/venueHooks";
import MyGoogleMap from "../../Elements/GoogleMap";
import { getAttributeValue } from "../../../utils/utility";
import SocialMediaLinks from "../../SocialMediaLinks";
import { useFetchEventsByVenueId } from "../../../hooks";

const VenueAccordion = ({
  data,
  attributes,
  loading,
  setRefreshTrigger = () => {},
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [itemToDelete, setItemToDelete] = useState(false);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const [state, dispatch] = useAppState();

  const navigate = useNavigate();
  const location = useLocation();
  const { deleteVenue, loading: deleting } = useDeleteVenue();
  const { events, loading: eventsLoading } = useFetchEventsByVenueId(
    itemToDelete?.id
  );

  const handleViewDetails = useCallback((e, id) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("venueId", id);
    navigate(`${location.pathname}?${searchParams.toString()}`);
    // handle view details
  }, []);

  const handleEdit = useCallback((e, id) => {
    e.stopPropagation();
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("venueId", id);
    navigate(`/editVenue?${searchParams.toString()}`);
  }, []);

  const handleDelete = useCallback((e, venueToDelete) => {
    e.stopPropagation();
    setItemToDelete(venueToDelete);
  }, []);

  const handleDeleteVenue = () => {
    deleteVenue(itemToDelete?.id).then(() => {
      handleCloseDialog();
    });
  };
  const handleCloseDialog = () => {
    setItemToDelete(null);
    setRefreshTrigger();
  };
  return (
    <>
      {loading ? (
        <Box width={"100%"}>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </Box>
      ) : (
        <Box>
          {data?.map((venue, index) => (
            <Accordion
              slotProps={{ transition: { unmountOnExit: true } }}
              key={index}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Box
                  sx={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                    justifyContent: { xs: "space-between", md: "normal" },
                  }}
                >
                  <Box sx={{ flexGrow: { xs: 0, md: 1 } }}>
                    <Typography
                      variant="h6"
                      component="div"
                      sx={{ fontWeight: "bold" }}
                    >
                      {venue.venue_name || "Venue Title"}
                    </Typography>
                    <Typography variant="body2" sx={{ display: "block" }}>
                      {venue?.address1}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ display: { md: "none", xs: "block" } }}
                    >
                      {/* <LocationOnIcon
                        sx={{ mr: 0.2, height: "16px", color: "red" }}
                      /> */}
                      {venue?.venue_city}
                    </Typography>
                    <Typography
                      variant="body2"
                      sx={{ display: { md: "none", xs: "block" } }}
                    >
                      {venue?.venue_state}
                    </Typography>
                  </Box>
                  <Box sx={{ mr: 5, display: { xs: "none", md: "block" } }}>
                    <Typography variant="body2" sx={{ textAlign: "right" }}>
                      {venue?.venue_city}
                    </Typography>
                    <Typography variant="body2" sx={{ textAlign: "right" }}>
                      {venue?.venue_state}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      mr: {
                        xs: 0,
                        md: 5,
                      },
                      display: "flex",
                      flexDirection: { xs: "column", md: "row" },
                    }}
                  >
                    <IconButton
                      aria-label="details"
                      onClick={(e) => handleViewDetails(e, venue.id)}
                    >
                      <VisibilityIcon sx={{ color: "#2a892a" }} />
                    </IconButton>
                    <IconButton
                      aria-label="edit"
                      onClick={(e) => handleEdit(e, venue.id)}
                    >
                      <EditIcon sx={{ color: "#307fc1" }} />
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={(e) => handleDelete(e, venue)}
                    >
                      <DeleteIcon sx={{ color: "#e34e4e" }} />
                    </IconButton>
                  </Box>
                </Box>
              </AccordionSummary>
              <AccordionDetails>
                {attributes.map((attribute, idx) => (
                  <Attribute key={idx + attribute.name}>
                    <AttributeLabel>{attribute.label}</AttributeLabel>
                    <AttributeValue>
                      {attribute?.component
                        ? getAttributeValue(attribute, venue, state)
                        : venue[attribute.name]}
                    </AttributeValue>
                  </Attribute>
                ))}
                {venue?.socialMediaLinks && (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                    <SocialMediaLinks
                      size={"48"}
                      links={venue.socialMediaLinks}
                    />
                  </Box>
                )}
              </AccordionDetails>
            </Accordion>
          ))}

          <CustomAlertDialog
            title={"Delete Venue Confirmation"}
            description={
              eventsLoading
                ? `Fetching Venue Events...`
                : events?.length > 0
                ? `Can't delete the venue, Venue contains events`
                : `Are you sure you want to delete the Venue ${itemToDelete?.venue_name}`
            }
            proceed={handleDeleteVenue}
            open={Boolean(itemToDelete?.id)}
            isLoading={deleting || eventsLoading}
            handleClose={handleCloseDialog}
            disabled={!eventsLoading && events?.length > 0}
          />
        </Box>
      )}
    </>
  );
};

export default VenueAccordion;
