import { useLocation, useNavigate } from "react-router";
import CustomDrawer from "../../Elements/CustomDrawer";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
import moment from "moment";
import { Link } from "react-router-dom";
import MyGoogleMap from "../../Elements/GoogleMap";
import { useAppState } from "../../../appContext";
import EventAccordion from "../Events/EventAccordion";
import { useDeleteVenue, useFetchVenueById } from "../../../hooks/venueHooks";
import { useCallback, useEffect, useState } from "react";
import CustomAlertDialog from "../../Elements/AlertDialogue";
import { getAttributeValue } from "../../../utils/utility";
import SocialMediaLinks from "../../SocialMediaLinks";
import { useNotification } from "../../Notification";
import { useFetchEventsByVenueId } from "../../../hooks";
import UploadEvents from "./UploadEvents";
const attributes = [
  {
    label: "Venue",
    name: "venue_name",
    sortable: true,
  },
  {
    label: "Address",
    name: "address1",
  },
  {
    label: "City",
    name: "venue_city",
  },
  {
    label: "Zone",
    name: "tzone",
  },
  {
    label: "Internal Link",
    name: "internallink",
  },
  {
    label: "Modified Date",
    name: "modifieddate",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
];

export const VenueDetails = ({
  onDrawerClose,
  setRefreshTrigger = () => {},
}) => {
  const [state, dispatch] = useAppState();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const { deleteVenue, loading: deleting } = useDeleteVenue();
  const venueId = searchParams.get("venueId");
  const [showPastEvents, setShowPastEvents] = useState(false);

  const { venue, loading } = useFetchVenueById(venueId);

  const [itemToDelete, setItemToDelete] = useState(false);
  const [hasEvents, setHasEvents] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();
  const [localPagination, setLocalPagination] = useState({ skip: 0, take: 20 });
  const [currentPage, setCurrentPage] = useState(0);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleOpenDialogForEventsUpload = () => {
    setDialogOpen(true);
  };

  const handleCloseDialogForEventsUpload = () => {
    setDialogOpen(false);
  };
  const {
    events,
    loading: eventsLoading,
    totalItems,
  } = useFetchEventsByVenueId(venueId, showPastEvents);

  useEffect(() => {
    if (events && events.length > 0) {
      setHasEvents(true);
    } else {
      setHasEvents(false);
    }
  }, [events]);

  const getPaginatedResults = () => {
    let searchResults = events.slice(
      localPagination.skip,
      localPagination.take + localPagination.skip
    );

    return searchResults;
  };

  const handleEdit = useCallback((e, id) => {
    e.stopPropagation();
    searchParams.set("venueId", id);
    navigate(`/editVenue?${searchParams.toString()}`);
  }, []);
  const handleDelete = useCallback(
    (e, item) => {
      e.stopPropagation();
      if (hasEvents) {
        showWarningMessage("Venue contains Events");
        return;
      }
      setItemToDelete(item);
    },
    [hasEvents]
  );

  const handleDeleteVenue = () => {
    deleteVenue(itemToDelete?.id).then(() => {
      handleCloseDialog();
    });
  };
  const handleCloseDialog = () => {
    setItemToDelete(null);
    searchParams.delete("venueId");

    // Navigate to the same path but with the modified search parameters
    navigate(`${location.pathname}?${searchParams.toString()}`);
    setRefreshTrigger();
  };

  return (
    <>
      <CustomDrawer
        open={venueId}
        onClose={() => {
          onDrawerClose();
        }}
        loading={loading}
        title={venue?.venue_name}
      >
        {venue ? (
          <>
            <Box
              sx={{
                padding: 2,
                flex: 1,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mb: 5,
                  height: "250px",
                }}
              >
                <Box
                  component="img"
                  src={
                    venue.imageUrl
                      ? venue.imageUrl
                      : "https://via.placeholder.com/1100x250?text=Cover+Photo"
                  }
                  alt="Cover Photo"
                  sx={{
                    objectFit: "cover",
                  }}
                />
              </Box>
              <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>
                Venue Details
              </h4>
              {attributes.map((attribute, idx) => (
                <Attribute key={idx + attribute.name}>
                  <AttributeLabel>{attribute.label}</AttributeLabel>
                  <AttributeValue>
                    {attribute?.component
                      ? getAttributeValue(attribute, venue, state)
                      : venue[attribute.name]}
                  </AttributeValue>
                </Attribute>
              ))}
              {venue?.socialMediaLinks && (
                <Box sx={{ ml: 1, mt: 1 }}>
                  <SocialMediaLinks
                    size={"48"}
                    links={venue.socialMediaLinks}
                  />
                </Box>
              )}
              <Divider sx={{ my: 2 }} />

              {/* <MyGoogleMap
                markers={[venue]}
                centerProp={{
                  lng: venue?.lon,
                  lat: venue?.lat,
                }}
              /> */}
              <Divider sx={{ my: 2 }} />
              <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>
                Events
              </h4>

              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPastEvents}
                    onChange={() => {
                      setShowPastEvents((p) => !p);
                      setCurrentPage(0);
                    }}
                  />
                }
                label="Show Past Events"
              />
              <Button
                variant="contained"
                onClick={handleOpenDialogForEventsUpload}
              >
                Upload Events
              </Button>
              <UploadEvents
                open={dialogOpen}
                handleClose={handleCloseDialogForEventsUpload}
                venue={venue}
              />
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {(eventsLoading || loading || totalItems > 0) && (
                  <Box sx={{ flex: "1 0 calc(50% - 16px)" }}>
                    <EventAccordion
                      data={getPaginatedResults()}
                      showIndicators={true}
                      loading={eventsLoading || loading}
                      totalCount={totalItems}
                      showCompactDesign={true}
                      showImage={false}
                      isMainView={false}
                      setPaginationProps={(skip, take) => {
                        setLocalPagination({
                          skip,
                          take,
                        });
                      }}
                      currentPage={currentPage}
                      setCurrentPage={(v) => setCurrentPage(v)}
                    />
                  </Box>
                )}
                {events?.length === 0 && !eventsLoading && (
                  <Typography
                    sx={{
                      display: "flex",
                      ml: "45%",
                    }}
                  >
                    No Data found
                  </Typography>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-evenly",
                gap: 5,
                padding: 2,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={(e) => handleDelete(e, venue)}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => handleEdit(e, venue?.id)}
              >
                Edit
              </Button>
            </Box>
          </>
        ) : (
          <CircularProgress />
        )}
        <CustomAlertDialog
          title={"Delete Venue Confirmation"}
          description={`Are you sure you want to delete the Venue ${itemToDelete?.venue_name}`}
          proceed={handleDeleteVenue}
          open={Boolean(itemToDelete?.id)}
          isLoading={deleting}
          handleClose={handleCloseDialog}
        />
      </CustomDrawer>
    </>
  );
};
