import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  IconButton,
  Button,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";

import Card from "../../Elements/Card";
import CustomSearchBar from "../../Elements/CustomSearchBar";
import { useAppState } from "../../../appContext";
import ChecklistFilter from "../Filters/ChecklistFilter";
import { useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import BandAccordion from "./BandAccordion";
import { BandDetails } from "./BandDetails";
import { useFetchBands } from "../../../hooks";
import BandCard from "./BandCard";
import { paginate } from "../../../utils/utility";

const ITEMS_PER_PAGE = [20, 50, 100, 200, 500];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,

    ...(open && {
      marginLeft: 0,
    }),
  })
);

export default function BandsLayout() {
  const [open, setOpen] = useState(false);
  const [viewMode, setViewMode] = useState("accordion"); // 'cards' or 'accordion'
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bandId = searchParams.get("bandId");
  const [expanded, setExpanded] = useState({});
  const [state, dispatch] = useAppState();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Initialize refresh trigger
  const fromNavigateToDetails = location.state?.fromNavigateToDetails || false;
  const removeQueryParam = (paramToRemove) => {
    // Create a URLSearchParams object from the current search string
    const searchParams = new URLSearchParams(location.search);

    // Remove the specified query parameter
    searchParams.delete(paramToRemove);

    // Navigate to the same path but with the modified search parameters
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  useEffect(() => {
    if (fromNavigateToDetails) setRefreshTrigger((r) => r + 1);
  }, [fromNavigateToDetails]);

  const { bands, loading } = useFetchBands(refreshTrigger);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleAddBand = () => {
    navigate("/editBand");
  };
  const getFilteredResults = () => {
    if (!searchTerm) return bands; // Return the full array if no search term is provided

    // Convert the searchTerm to lowercase for case-insensitive comparison
    const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();

    return bands.filter((item) =>
      item.title_lowercase.includes(lowerCaseSearchTerm)
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Main open={open}>
        {/* Rest of your main content goes here */}
        <CustomSearchBar
          handleChange={(searchTerm) => setSearchTerm(searchTerm)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Button
              onClick={handleAddBand}
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add Band
            </Button>
          </Box>
        </Box>

        <BandCard
          data={paginate(getFilteredResults(), currentPage + 1, itemsPerPage)}
          loading={loading}
          setRefreshTrigger={() => setRefreshTrigger((r) => r + 1)}
        />
        {getFilteredResults().length > itemsPerPage && (
          <TablePagination
            component="div"
            count={getFilteredResults().length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={handleChangeItemsPerPage}
            rowsPerPageOptions={ITEMS_PER_PAGE}
          />
        )}
        {getFilteredResults()?.length === 0 && !loading && (
          <Typography
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            No Data found
          </Typography>
        )}
        {bandId && (
          <BandDetails
            setRefreshTrigger={() => setRefreshTrigger((r) => r + 1)}
            onDrawerClose={() => {
              removeQueryParam("bandId");
            }}
          />
        )}
      </Main>
    </Box>
  );
}
