import React, { useState, useEffect } from "react";
import { Autocomplete, TextField, CircularProgress } from "@mui/material";

export default function PaginatedAutocomplete({
  options,
  label,
  value,
  onChange,
  error,
  helperText,
  getOptionLabel = (op) => op,
  required = true,
  onFocus,
  isLoading,
  ...rest
}) {
  const [inputValue, setInputValue] = useState("");
  const [displayedOptions, setDisplayedOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // Show the first 20 elements initially
    setDisplayedOptions(options.slice(0, 20));
  }, [options]);

  useEffect(() => {
    if (inputValue) {
      setLoading(true);
      // Filter options based on the input value
      const filteredOptions = options.filter((option) =>
        getOptionLabel(option)
          .toLowerCase()
          .startsWith(inputValue.toLowerCase())
      );
      // Show up to the first 20 filtered elements
      setDisplayedOptions(filteredOptions.slice(0, 20));
      setLoading(false);
    } else {
      // Reset to the first 20 elements when input is cleared
      setDisplayedOptions(options.slice(0, 20));
    }
  }, [inputValue, options]);

  return (
    <Autocomplete
      fullWidth
      value={value}
      onChange={onChange}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      options={displayedOptions}
      getOptionLabel={getOptionLabel ? getOptionLabel : undefined}
      loading={loading || isLoading}
      // onFocus={onFocus}

      {...rest}
      renderInput={(params) => (
        <TextField
          {...params}
          onFocus={onFocus}
          label={label}
          error={!!error}
          helperText={helperText || ""}
          // sx={{ my: 2 }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
          required={required}
        />
      )}
    />
  );
}
