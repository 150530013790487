import React, { useEffect, useState } from "react";
import { Box, InputBase, Paper, IconButton } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "../../utils";

export default function CustomSearchBar({ handleChange }) {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchTerm = useDebounce(searchQuery, 500);
  useEffect(() => {
    handleChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      // Prevent default form submission or any action on Enter key press
      event.preventDefault();
    }
  };
  return (
    <Paper
      component="form"
      sx={{
        display: "flex",
        alignItems: "center",
        // width: "100%",
        margin: "auto",
        padding: "2px 4px",
        marginBottom: "20px",
        boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, pl: 1 }}
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        value={searchQuery}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown} // Handle the key down event
      />
      {/* <IconButton
        type="button"
        sx={{ p: "10px" }}
        aria-label="search"
        onClick={handleSearch}
      > */}
      <SearchIcon sx={{ p: "10px" }} />
      {/* </IconButton> */}
    </Paper>
  );
}
