import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  //   Card,
  CardContent,
  Collapse,
  IconButton,
  TextField,
  Typography,
  Button,
  Divider,
  Paper,
} from "@mui/material";
import FilterListIcon from "@mui/icons-material/FilterList";

import Card from "../../Elements/Card";
import CustomSearchBar from "../../Elements/CustomSearchBar";
import EventsData from "./EventCards";
import { useAppState } from "../../../appContext";
import ChecklistFilter from "../Filters/ChecklistFilter";
import ViewModuleIcon from "@mui/icons-material/ViewModule"; // Icon for card view
import ViewListIcon from "@mui/icons-material/ViewList"; // Icon for accordion view
import EventAccordion from "./EventAccordion"; // Import EventAccordion component
import CustomDrawer from "../../Elements/CustomDrawer";
import { useLocation, useNavigate } from "react-router-dom";
import { EventDetails } from "./EventDetails";
import AddIcon from "@mui/icons-material/Add";
import { useFetchEvents } from "../../../hooks";
import isEqual from "lodash/isEqual";
import YearFilter from "../Filters/YearFilter";
import { useDebounce } from "../../../utils";
import DateFilter from "../Filters/DateFilter";
import VenueFilter from "../Filters/VenueFilter";
import BandFilter from "../Filters/BandFilter";
import firebase from "firebase/compat/app";

const drawerWidth = 400;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    // marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);
const attributes = [
  {
    label: "Title",
    name: "title",
    sortable: true,
  },
  {
    label: "Description",
    name: "eventdescription",
    component: {
      type: "html",
    },
  },
  {
    label: "Event Date",
    name: "main_date",
    sortable: true,
    component: {
      type: "dateTime",
    },
  },
  {
    label: "Bands",
    name: "bands",
    state: "bands",
    component: {
      type: "array",
      contextProp: "bandname",
      queryParam: "bandId",
    },
  },
  {
    label: "Venue",
    name: "venue",
    state: "venues",
    component: {
      type: "object",
      contextProp: "venue_name",
      queryParam: "venueId",
    },
  },
];
export default function EventsLayout() {
  const [open, setOpen] = useState(false);
  const [viewMode, setViewMode] = useState("accordion"); // 'cards' or 'accordion'
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const eventId = searchParams.get("eventId");
  const [state, dispatch] = useAppState();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [queryStrings, setQueryString] = useState([]);
  const [paginationProps, setPaginationProps] = useState({ skip: 0, take: 0 });
  const [localPagination, setLocalPagination] = useState({ skip: 0, take: 20 });
  const [currentPage, setCurrentPage] = useState(0);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Initialize refresh trigger
  const fromNavigateToDetails = location.state?.fromNavigateToDetails || false;

  useEffect(() => {
    if (fromNavigateToDetails) {
      setRefreshTrigger((r) => r + 1);
    }
  }, [fromNavigateToDetails]);
  const [filters, setFilters] = useState({
    bands: [],
    venues: [],
    states: [],
    cities: [],
    // years: [],
    date: [],
  });

  const removeQueryParam = (paramToRemove) => {
    // Create a URLSearchParams object from the current search string
    const searchParams = new URLSearchParams(location.search);

    // Remove the specified query parameter
    searchParams.delete(paramToRemove);

    // Navigate to the same path but with the modified search parameters
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  useEffect(() => {
    const startOfDay = new Date(filters.date[0]);
    startOfDay.setHours(0, 0, 0, 0);

    const endOfDay = new Date(filters.date[0]);
    endOfDay.setHours(23, 59, 59, 999);

    let queryStr = [
      ...(searchTerm?.length > 0
        ? [
            {
              field: "searchTerms",
              operator: "array-contains-any",
              value: searchTerm.toLowerCase().split(" "),
            },
            // {
            //   field: "title_lowercase",
            //   operator: ">=",
            //   value: debouncedSearchValue.toLowerCase(),
            // },
            // {
            //   field: "title_lowercase",
            //   operator: "<=",
            //   value: debouncedSearchValue.toLowerCase() + "\uf8ff",
            // },
            // {
            //   field: "eventdescription",
            //   operator: "==",
            //   value: searchTerm,
            // },
          ]
        : []),
      ...(filters.bands && filters.bands.length > 0
        ? [
            {
              field: "bandIds",
              operator: "array-contains-any",
              value: filters.bands.map((v) => v.id),
            },
          ]
        : []),
      ...(filters.venues && filters.venues.length > 0
        ? [
            {
              field: "venue",
              operator: "in",
              value: filters.venues.map((v) => v.id),
            },
          ]
        : []),
      ...(filters.states && filters.states.length > 0
        ? [
            {
              field: "state",
              operator: "in",
              value: filters.states,
            },
          ]
        : []),
      ...(filters.cities && filters.cities.length > 0
        ? [
            {
              field: "city",
              operator: "in",
              value: filters.cities,
            },
          ]
        : []),
      ...(filters.date && filters.date.length > 0
        ? [
            {
              field: "main_date",
              operator: ">=",
              value: firebase.firestore.Timestamp.fromDate(startOfDay), // Adjusted Timestamp.fromDate() for start of the day
            },
            {
              field: "main_date",
              operator: "<=",
              value: firebase.firestore.Timestamp.fromDate(endOfDay), // Adjusted Timestamp.fromDate() for end of the day
            },
          ]
        : []),

      // ...(filters.years.length > 0
      //   ? [
      //       {
      //         field: "main_date",
      //         operator: ">=",
      //         value: getFilteredDate(filters.years),
      //       },
      //     ]
      //   : []),
    ];
    if (!isEqual(queryStr, queryStrings)) {
      setQueryString([...queryStr]);
      setCurrentPage(0);
    }
  }, [filters, searchTerm]);

  const { events, loading, totalItems, isPaginated } = useFetchEvents(
    paginationProps.take,
    paginationProps.skip,
    queryStrings,
    paginationProps.take > 0,
    refreshTrigger
  );

  const filtersToRender = [
    {
      title: "Filter by Date",
      contextProp: "date",
      // friendlyName: "cities",
      type: "date",
      colorCode: {
        primary: "rgba(57,2,118,1)",
        secondary: "rgba(57,2,118,0.1)",
      },
    },

    {
      title: "Filter by Band",
      contextProp: "bands",
      friendlyName: "bandname",
      type: "bands",
      colorCode: {
        primary: "rgba(65,66,183,1)",
        secondary: "rgba(65,66,183,0.1)",
      },
    },
    {
      title: "Filter by Venue",
      contextProp: "venues",
      friendlyName: "venue_name",
      type: "venues",
      colorCode: {
        primary: "rgba(241,86,40,1)",
        secondary: "rgba(241,86,40,0.1)",
      },
    },

    {
      title: "Filter by State",
      contextProp: "states",
      friendlyName: "state",
      type: "checklist",
      colorCode: {
        primary: "rgba(10,172,0,1)",
        secondary: "rgba(10,172,0,0.1)",
      },
    },
    {
      title: "Filter by City",
      contextProp: "cities",
      friendlyName: "cities",
      type: "checklist",
      colorCode: {
        primary: "rgba(107,0,123,1)",
        secondary: "rgba(107,0,123,0.1)",
      },
    },
    // {
    //   title: "Filter by Year",
    //   contextProp: "years",
    //   // friendlyName: "cities",
    //   type: "year",
    //   colorCode: {
    //     primary: "rgba(57,2,118,1)",
    //     secondary: "rgba(57,2,118,0.1)",
    //   },
    // },
  ];

  const handleChange = (filterName) => (value) => {
    setFilters((prev) => ({ ...prev, [filterName.contextProp]: value }));
  };
  const getFilteredDate = (years) => {
    let startOfDay;
    if (years.length === 0) {
      return null;
    } else {
      const smallestYear = Math.min(...years);
      startOfDay = new Date(`01-01-${smallestYear}`);
    }
    startOfDay.setHours(0, 0, 0, 0);
    return startOfDay;
  };

  const handleAddEvent = () => {
    navigate("/editEvent");
  };
  const renderFilter = (filterName, expandState) => {
    switch (filterName?.type) {
      case "checklist": {
        return (
          <ChecklistFilter
            colors={filterName?.colorCode}
            selectedItems={filters?.[filterName?.contextProp]}
            onChange={handleChange(filterName)}
            data={state?.[filterName.contextProp]?.map((b, i) => ({
              id: b?.id
                ? b?.id
                : typeof b === "string"
                ? b
                : b[filterName.friendlyName],
              name: typeof b === "string" ? b : b[filterName.friendlyName],
            }))}
          />
        );
      }

      case "year": {
        return (
          <YearFilter
            colors={filterName?.colorCode}
            selectedItems={filters?.[filterName?.contextProp]}
            onChange={handleChange(filterName)}
          />
        );
      }
      case "date": {
        return (
          <DateFilter
            value={filters?.[filterName?.contextProp][0]}
            onChange={handleChange(filterName)}
          />
        );
      }
      case "venues": {
        return (
          <VenueFilter
            colors={filterName?.colorCode}
            selectedItems={filters?.[filterName?.contextProp]}
            onChange={handleChange(filterName)}
            isExpanded={expandState}
          />
        );
      }
      case "bands": {
        return (
          <BandFilter
            colors={filterName?.colorCode}
            selectedItems={filters?.[filterName?.contextProp]}
            onChange={handleChange(filterName)}
            isExpanded={expandState}
          />
        );
      }
    }
  };
  const [expandedState, setExpandedState] = useState({}); // State to track expanded status of each card

  const handleExpandChange = (filterName, expanded) => {
    setExpandedState((prevState) => ({
      ...prevState,
      [filterName]: expanded, // Update the expanded status for the specific filter
    }));
  };
  const getFiltersToRender = () => {
    return (
      <>
        {filtersToRender.map((filterName, index) => (
          <Box key={filterName + index} sx={{ margin: "8px" }}>
            <Card
              cardTitle={filterName.title}
              collapsible
              onExpandChange={(expanded) =>
                handleExpandChange(filterName, expanded)
              }
            >
              <Box
                padding="0"
                style={{
                  backgroundColor: "#fbfbfd",
                }}
              >
                <Box padding="20px 20px 0px 20px">
                  <Box>
                    {renderFilter(filterName, expandedState[filterName])}
                  </Box>
                </Box>
              </Box>
            </Card>
          </Box>
        ))}
      </>
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Drawer
        sx={{
          display: { xs: "none", sm: open ? "block" : "none" },
          width: drawerWidth,
          flexShrink: 0,
          zIndex: 1,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
            paddingTop: "90px", // Adjust this value to the height of your AppBar
            // paddingTop: "30px",
            background: "transparent",
            border: "none",
            zIndex: 1,

            "&::-webkit-scrollbar": {
              display: "none",
            },
          },
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        {getFiltersToRender()}
      </Drawer>
      <Main open={open}>
        {/* Rest of your main content goes here */}
        <CustomSearchBar
          handleChange={(searchTerm) => setSearchTerm(searchTerm)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Button
              onClick={() => setOpen(!open)}
              variant="contained"
              endIcon={<FilterListIcon />}
            >
              Show Filters
            </Button>
            {open && (
              <Paper
                sx={{
                  display: { sm: "none", width: "100%" },
                  border: "1px solid #307fc1",
                }}
              >
                {getFiltersToRender()}
              </Paper>
            )}
            <Button
              onClick={handleAddEvent}
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add Event
            </Button>
          </Box>
          <Box sx={{ display: "flex" }}>
            {/* <IconButton onClick={() => setViewMode("cards")}>
              <ViewModuleIcon />
            </IconButton>
            <IconButton onClick={() => setViewMode("accordion")}>
              <ViewListIcon />
            </IconButton> */}
          </Box>
        </Box>
        {/* Data display component will be here */}
        {events && (
          <EventAccordion
            loading={loading}
            totalCount={totalItems}
            setPaginationProps={(skip, take) => {
              if (isPaginated) {
                setPaginationProps({
                  skip,
                  take,
                });
              } else {
                setLocalPagination({
                  skip,
                  take,
                });
              }
            }}
            currentPage={currentPage}
            setCurrentPage={(v) => setCurrentPage(v)}
            data={events}
            attributes={attributes}
            setRefreshTrigger={() => setRefreshTrigger((r) => r + 1)}
          />
        )}
        {events?.length === 0 && !loading && (
          <Typography
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            No Data found
          </Typography>
        )}
        {/* {viewMode === "cards" ? (
          <EventsData data={getFilteredResults()} attributes={attributes} />
        ) : (
          <EventAccordion data={getFilteredResults()} attributes={attributes} />
        )} */}
        {eventId && (
          <EventDetails
            setRefreshTrigger={() => setRefreshTrigger((r) => r + 1)}
            onDrawerClose={() => {
              removeQueryParam("eventId");
            }}
          />
        )}
      </Main>
    </Box>
  );
}
