import React from "react";
import { Button, CircularProgress } from "@mui/material";

// Custom button component that accepts all the props of MUI Button plus an isLoading prop
const LoadingButton = ({ isLoading, children, disabled, ...props }) => {
  return (
    <Button {...props} disabled={isLoading || disabled}>
      {isLoading ? <CircularProgress size={24} /> : children}
    </Button>
  );
};

export default LoadingButton;
