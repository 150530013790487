import React, { useState, useMemo, useEffect } from "react";
import {
  Box,
  TextField,
  Chip,
  Checkbox,
  List,
  ListItem,
  Pagination,
  Skeleton,
} from "@mui/material";
import { useTheme } from "@mui/styles";
import { useFetchBands } from "../../../hooks";
import { useDebounce } from "../../../utils";
import { paginate } from "../../../utils/utility";

const BandFilter = ({ onChange, colors, selectedItems, isExpanded }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const [enableApi, setEnableApi] = useState(false);
  const theme = useTheme();
  const { bands: data, loading, totalItems } = useFetchBands(null, enableApi);

  useEffect(() => {
    if (isExpanded && data?.length <= 0) setEnableApi(true);
  }, [isExpanded]);
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggle = (value) => {
    const currentIndex = selectedItems.findIndex(
      (item) => item.id === value.id
    );
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // setSelectedItems(newChecked);
    if (onChange) {
      onChange(newChecked);
    } else console.log(newChecked);
  };

  const getFilteredResults = () => {
    if (!searchTerm) return data; // Return the full array if no search term is provided

    // Convert the searchTerm to lowercase for case-insensitive comparison
    const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();

    return data?.filter((item) =>
      item.title_lowercase.includes(lowerCaseSearchTerm)
    );
  };

  const handleDeleteChip = (chipToDelete) => () => {
    handleToggle(chipToDelete);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Box sx={{ width: { xs: "100%", sm: 400 }, maxWidth: "100%" }}>
      <TextField
        fullWidth
        id="search-field"
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ bgcolor: "#fff" }}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          my: 2,
          // bgcolor: "#fff",
        }}
      >
        {!loading &&
          selectedItems.map((item) => (
            <Chip
              size="small"
              key={item}
              label={data.find((d) => d.id === item.id)?.bandname}
              onDelete={handleDeleteChip(item)}
              sx={{
                background: colors?.secondary
                  ? colors?.secondary
                  : theme?.primaryColor,
                color:
                  colors?.secondary && colors?.primary
                    ? colors?.primary
                    : "#fff",
                "& .MuiChip-deleteIcon": {
                  color:
                    colors?.secondary && colors?.primary
                      ? colors?.primary
                      : "#fff",
                },
              }}
            />
          ))}
      </Box>
      <>
        {loading && (
          <Box width={"100%"}>
            <Skeleton height={30} animation="wave" />
            <Skeleton height={30} animation="wave" />
            <Skeleton height={30} animation="wave" />
          </Box>
        )}
      </>
      <List dense sx={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
        {getFilteredResults() &&
          !loading &&
          paginate(getFilteredResults(), page, itemsPerPage).map((item) => (
            <ListItem key={item.id} dense>
              <Checkbox
                edge="start"
                sx={{ padding: "2px 0px" }}
                checked={
                  selectedItems.filter((s) => s.id === item.id).length > 0
                }
                tabIndex={-1}
                disableRipple
                inputProps={{
                  "aria-labelledby": `checkbox-list-label-${item.id}`,
                }}
                onChange={() => handleToggle(item)}
              />
              {item.bandname}
            </ListItem>
          ))}
      </List>
      <Pagination
        count={Math.ceil(getFilteredResults().length / itemsPerPage)}
        page={page}
        onChange={handleChangePage}
        size="small"
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default BandFilter;
