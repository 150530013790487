import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  Chip,
  Grid,
  Box,
  FormControlLabel,
  Checkbox,
  Button,
  Skeleton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useAppState } from "../../../appContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router";
import ImageCropper from "../Events/ImageCropper";
import {
  useAddBand,
  useFetchBandById,
  useFetchBandByInternalLink,
  useUpdateBand,
} from "../../../hooks";
import SocialMediaLinkAdder from "../../SocialMediaLinkAdder";
import { generateCleanWordsWithLimit } from "../../../utils/utility";
import { auth } from "../../../firebase-config.";

const BandForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const bandId = searchParams.get("bandId");
  const { band: bandData, loading: bandDataLoading } = useFetchBandById(bandId);
  const [isEditMode, setIsEditMode] = useState(false);

  const [guid, setGuid] = useState(uuidv4());
  const [bandName, setBandName] = useState("");
  const [alias, setAlias] = useState([]);
  const [aliasInput, setAliasInput] = useState("");
  const [internalLink, setInternalLink] = useState("");
  const [description, setDescription] = useState("");
  const [originals, setOriginals] = useState(0);
  const [socialMediaLinks, setSocialMediaLinks] = useState([]);
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState(null);

  const { addBand, loading: addingBand } = useAddBand();
  const { updateBand, loading: updatingBand } = useUpdateBand();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchBand, loading: bandLoading } = useFetchBandByInternalLink();

  const navigate = useNavigate();

  const removeSlashes = (str) => {
    if (!str) return null;
    if (str.startsWith("/")) {
      str = str.slice(1);
    }
    if (str.endsWith("/")) {
      str = str.slice(0, -1);
    }
    return str;
  };
  const [errors, setErrors] = useState({
    bandName: "",
    internalLink: "",
  });

  useEffect(() => {
    if (bandData) {
      setIsEditMode(true);
      setGuid(bandData.id);
      setBandName(bandData.bandname);
      setAlias(bandData.alias || []);
      setInternalLink(removeSlashes(bandData.internallink) || "");
      setDescription(bandData.description || "");
      setOriginals(bandData.originals || 0);
      setSocialMediaLinks(bandData.socialMediaLinks || []);
      setImageUrl(bandData.imageUrl || "");
    }
  }, [bandData]);

  const validate = () => {
    let temp = {};
    temp.bandName = bandName ? "" : "This field is required.";
    temp.internalLink = internalLink
      ? /^[a-zA-Z0-9-]+$/.test(internalLink)
        ? ""
        : "Only alphanumeric characters and hyphens are allowed."
      : "This field is required.";

    setErrors(temp);

    return Object.values(temp).every((x) => x === "");
  };

  const handleImageCrop = (img, croppedDataUrl) => {
    if (!img || !croppedDataUrl) {
      setImage("");
      return;
    }
    setImage(img);
    setImageUrl(croppedDataUrl);
  };

  const handleImageUpload = async (imageFile) => {
    if (!imageFile) {
      return imageUrl || "";
    }
    const storage = getStorage();
    const storageRef = ref(storage, "bands/" + bandName.replaceAll(" ", "-"));

    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      return "";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);

      const finalImageUrl = image ? await handleImageUpload(image) : imageUrl;

      const getCurrentUserId = () => {
        const user = auth.currentUser;
        return user ? user.uid : null;
      };
      const userId = getCurrentUserId();
      const formData = {
        id: guid,
        bandname: bandName,
        originals,
        alias,
        internallink: `/${internalLink.toLowerCase()}/`,
        imageUrl: finalImageUrl,
        socialMediaLinks,
        description,
        modifieddate: dayjs().toISOString(),
        title_lowercase: bandName.toLowerCase(),
        searchTerms: generateCleanWordsWithLimit(
          `${alias.join(" ")} ${bandName.toLowerCase()}`
        ),
        userId: userId,
      };
      fetchBand(internalLink).then(async (res) => {
        if (isEditMode) {
          if (res?.id && res?.id !== bandData.id) {
            let err = {
              ...errors,
              internalLink: `${internalLink} already exists`,
            };
            setErrors(err);
          } else {
            await updateBand(guid, formData);
            navigateToDetails(guid);
          }
        } else {
          if (res?.id) {
            let err = {
              ...errors,
              internalLink: `${internalLink} already exists`,
            };
            setErrors(err);
          } else {
            await addBand({ ...formData, createdAt: dayjs().toISOString() });

            navigateToDetails(guid);
          }
        }
      });

      setIsLoading(false);
    }
  };

  const handleInputKeyDown = (event) => {
    if ((event.key === "Enter" || event.key === ",") && aliasInput.trim()) {
      event.preventDefault();
      setAlias([...alias, aliasInput.trim()]);
      setAliasInput("");
    }
  };

  const navigateToDetails = (id) => {
    const searchParams = new URLSearchParams(location.search);
    if (id) {
      searchParams.set("bandId", id);
      navigate(`/bands?${searchParams.toString()}`);
    } else {
      navigate("/bands");
    }
  };
  if (bandDataLoading) {
    return (
      <>
        <Box width={"100%"}>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </Box>
      </>
    );
  }

  return (
    <Container maxWidth={false} sx={{ mt: 12 }}>
      <Card
        raised
        sx={{ margin: "auto", maxWidth: "calc(100% - 32px)", boxShadow: 3 }}
      >
        <Box sx={{ bgcolor: "#307fc1", color: "white", p: 2 }}>
          <Typography variant="h6">
            {isEditMode ? "Edit Band" : "Add Band"}
          </Typography>
        </Box>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={handleSubmit} noValidate>
              <Box
                sx={{
                  padding: 2,
                  display: "flex",
                }}
              >
                <ImageCropper
                  src={isEditMode ? imageUrl : ""}
                  onCrop={handleImageCrop}
                  aspectRatio={1}
                />
              </Box>

              {/* <Typography variant="subtitle1" sx={{ mt: 6, color: "gray" }}>
                ID: {guid}
              </Typography> */}

              <TextField
                fullWidth
                label="Band Name"
                value={bandName}
                onChange={(e) => setBandName(e.target.value)}
                error={!!errors.bandName}
                helperText={errors.bandName || ""}
                sx={{ my: 2 }}
                required
              />

              <Grid container spacing={3}>
                <Grid item xs={12} md={8}>
                  <TextField
                    value={aliasInput}
                    onChange={(e) => setAliasInput(e.target.value)}
                    onKeyDown={handleInputKeyDown}
                    label="Add Aliases"
                    fullWidth
                    helperText={"Press enter or add comma (,) to add a value"}
                    InputProps={{
                      startAdornment: alias.map((chip, index) => (
                        <Chip
                          color="primary"
                          variant="outlined"
                          key={index + chip}
                          label={chip}
                          onDelete={() => {
                            return setAlias((chips) =>
                              chips.filter((c) => c !== chip)
                            );
                          }}
                          style={{ marginRight: 5 }}
                        />
                      )),
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <TextField
                    fullWidth
                    label="Internal Link"
                    value={internalLink}
                    onChange={(e) => setInternalLink(e.target.value)}
                    error={!!errors.internalLink}
                    helperText={errors.internalLink || ""}
                    required
                  />
                </Grid>
              </Grid>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={originals}
                        onChange={() => setOriginals((o) => (o === 1 ? 0 : 1))}
                      />
                    }
                    label="Originals"
                  />
                </Grid>
              </Grid>
              <SocialMediaLinkAdder
                initialLinks={socialMediaLinks}
                onLinksChange={(v) => {
                  console.log("Links changed");
                  setSocialMediaLinks(v);
                }}
              />
              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                sx={{ my: 2 }}
              />

              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigateToDetails(null)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={
                    addingBand || updatingBand || isLoading || bandLoading
                  }
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </LocalizationProvider>
        </CardContent>
      </Card>
    </Container>
  );
};

export default BandForm;
