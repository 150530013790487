import React from "react";

import { FC } from "react";
import { styled } from "@mui/system";
import { Box } from "@mui/material";

const StyledDiv = styled("div")({
  display: "flex",
  padding: "4px 0px",
  flexDirection: "row",
});

export const Attribute = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        padding: "4px 0px",
        flexDirection: { xs: "column", md: "row" },
      }}
    >
      {props.children}
    </Box>
  );
};

export * from "./AttributeLabel";
export * from "./AttributeValue";
