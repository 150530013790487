import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  IconButton,
  Button,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";

import CustomSearchBar from "../../Elements/CustomSearchBar";

import { Link, useLocation, useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";

import { paginate } from "../../../utils/utility";
import { useFetchPages } from "./../../../hooks/pageHooks";

const ITEMS_PER_PAGE = [20, 50, 100, 200, 500];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,

    ...(open && {
      marginLeft: 0,
    }),
  })
);

export default function PagesLayout() {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Initialize refresh trigger
  const fromNavigateToDetails = location.state?.fromNavigateToDetails || false;

  useEffect(() => {
    if (fromNavigateToDetails) setRefreshTrigger((r) => r + 1);
  }, [fromNavigateToDetails]);

  const { pages, loading } = useFetchPages(true);

  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleAddPage = () => {
    navigate("/editPage");
  };
  const getFilteredResults = () => {
    if (!searchTerm) return pages; // Return the full array if no search term is provided

    // Convert the searchTerm to lowercase for case-insensitive comparison
    const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();

    return pages.filter((item) =>
      item.title_lowercase.includes(lowerCaseSearchTerm)
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Main open={open}>
        {/* Rest of your main content goes here */}
        <CustomSearchBar
          handleChange={(searchTerm) => setSearchTerm(searchTerm)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Button
              onClick={handleAddPage}
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add Page
            </Button>
          </Box>
        </Box>
        {paginate(getFilteredResults(), currentPage + 1, itemsPerPage).map(
          (p, i) => {
            return (
              <Box key={p.internallink + i} sx={{ mb: 2 }}>
                <a
                  href={`https://www.webtunes.com${p.internallink}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {p.title}
                </a>
              </Box>
            );
          }
        )}
        {/* <BandCard
          data={paginate(getFilteredResults(), currentPage + 1, itemsPerPage)}
          loading={loading}
          setRefreshTrigger={() => setRefreshTrigger((r) => r + 1)}
        /> */}
        {getFilteredResults().length > itemsPerPage && (
          <TablePagination
            component="div"
            count={getFilteredResults().length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={handleChangeItemsPerPage}
            rowsPerPageOptions={ITEMS_PER_PAGE}
          />
        )}
        {getFilteredResults()?.length === 0 && !loading && (
          <Typography
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            No Data found
          </Typography>
        )}
      </Main>
    </Box>
  );
}
