import { useState, useEffect } from "react";
import {
  collection,
  getDocs,
  updateDoc,
  deleteDoc,
  doc,
  where,
  query,
  setDoc,
} from "firebase/firestore";
import { db } from "../firebase-config.";
import { useNotification } from "../components/Notification";

// Custom hook to fetch all users from Firestore
export const useFetchUsers = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const querySnapshot = await getDocs(collection(db, "users"));
        const usersData = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setUsers(usersData);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching users:", error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  return { users, loading };
};

// Custom hook to add a new user to Firestore
export const useAddUser = () => {
  const [loading, setLoading] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const addUser = async (userData) => {
    try {
      setLoading(true);
      // Ensure `userData` includes `isApproved` field
      await setDoc(doc(db, "users", userData.email), {
        ...userData,
        isApproved: false // Set to false by default
      });
      showSuccessMessage("User added successfully");
    } catch (error) {
      showWarningMessage("Error adding user:", error);
    } finally {
      setLoading(false);
    }
  };

  return { addUser, loading };
};

// Custom hook to update an existing user in Firestore
export const useUpdateUser = () => {
  const [loading, setLoading] = useState(false);

  const updateUser = async (userId, userData) => {
    try {
      setLoading(true);
      const userRef = doc(db, "users", userId);
      await updateDoc(userRef, userData);
      console.log("User updated successfully");
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setLoading(false);
    }
  };

  return { updateUser, loading };
};

// Custom hook to delete a user from Firestore
export const useDeleteUser = () => {
  const [loading, setLoading] = useState(false);

  const deleteUser = async (userId) => {
    try {
      setLoading(true);
      await deleteDoc(doc(db, "users", userId));
      console.log("User deleted successfully");
    } catch (error) {
      console.error("Error deleting user:", error);
    } finally {
      setLoading(false);
    }
  };

  return { deleteUser, loading };
};
export const useFetchUser = () => {
  const fetchUserByEmail = async (email) => {
    try {
      const q = query(
        collection(db, "users"),
        where("email", "==", email.toLowerCase())
      );
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const user = querySnapshot.docs[0].data();
        return { user, error: null };
      } else {
        return { user: null, error: "User not found" };
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      return { user: null, error: error.message };
    }
  };

  return fetchUserByEmail;
};
