import React, { useState } from "react";
import {
  TextField,
  Button,
  Link,
  Grid,
  Typography,
  CircularProgress,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useUserAuth } from "../../context/UserAuthContext";
import { useAddUser } from "../../hooks";
import { useAppState } from "../../appContext";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh", // Set minimum height to fill the entire viewport
    background: "linear-gradient(to right, #ff6e7f, #bfe9ff)", // Example gradient background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  formContainer: {
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    textAlign: "center",
    width: "30%",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(2),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

const SignupForm = () => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    // dateOfBirth: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isFormValid, setIsFormValid] = useState(false);
  const { signUp } = useUserAuth();
  const [state, dispatch] = useAppState();
  const { addUser, loading } = useAddUser();
  const navigate = useNavigate();
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
    validateField(name, value);
  };

  const validateField = (fieldName, value) => {
    let errors = { ...formErrors };

    switch (fieldName) {
      case "firstName":
      case "lastName":
        errors[fieldName] = value.trim() === "" ? "This field is required" : "";
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        errors[fieldName] = !emailRegex.test(value)
          ? "Please enter a valid email address"
          : "";
        break;
      case "password":
        errors[fieldName] =
          value.length < 6 ? "Password must be at least 6 characters long" : "";
        break;
      default:
        break;
    }

    setFormErrors(errors);
    setIsFormValid(Object.values(errors).every((error) => error === ""));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid) {
      alert("Please fill in all required fields correctly.");
      return;
    }
    setIsLoading(true);
    const res = await signUp(email, password, `${firstName} ${lastName}`);
    const u = delete formData.password;
    addUser(formData).then(() => {
      setIsLoading(false);
      navigate("/");
    });
  };

  const { firstName, lastName, email, password } = formData;
  const username =
    email && email.includes("@") && email.includes(".")
      ? email.split("@")[0]
      : "";
  return (
    <div className={classes.root}>
      <div className={classes.formContainer}>
        <Typography style={{ fontFamily: "fantasy" }} variant="h4" gutterBottom>
          Webtune Admin Signup
        </Typography>
        <form className={classes.form} onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="First Name"
                name="firstName"
                value={firstName}
                onChange={handleChange}
                error={!!formErrors.firstName}
                helperText={formErrors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                variant="outlined"
                fullWidth
                label="Last Name"
                name="lastName"
                value={lastName}
                onChange={handleChange}
                error={!!formErrors.lastName}
                helperText={formErrors.lastName}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Email Address"
                name="email"
                type="email"
                value={email}
                onChange={handleChange}
                error={!!formErrors.email}
                helperText={formErrors.email}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                fullWidth
                label="Username (Read Only)"
                name="username"
                value={username}
                disabled
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Password"
                name="password"
                type="password"
                value={password}
                onChange={handleChange}
                error={!!formErrors.password}
                helperText={formErrors.password}
              />
            </Grid>
            {/* <Grid item xs={12}>
              <TextField
                variant="outlined"
                required
                fullWidth
                label="Date of Birth"
                name="dateOfBirth"
                type="date"
                InputLabelProps={{
                  shrink: true,
                }}
                value={dateOfBirth}
                onChange={handleChange}
              />
            </Grid> */}
          </Grid>
          <Button
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            disabled={!isFormValid}
            style={{ margin: "16px 0", width: "65%" }}
          >
            {loading || isLoading ? <CircularProgress /> : "Sign Up"}
          </Button>
          <Button
            fullWidth
            variant="contained"
            onClick={() => console.log("Cancelled")}
            style={{ width: "65%" }}
          >
            Cancel
          </Button>
        </form>
        <Link style={{ marginTop: "16px" }} href="/login" variant="body2">
          Already have an account? Log in
        </Link>
      </div>
    </div>
  );
};

export default SignupForm;
