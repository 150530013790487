// import React, { useEffect, useState } from "react";
// import {
//   GoogleMap,
//   LoadScript,
//   MarkerF,
//   InfoWindow,
//   MarkerClustererF,
//   InfoWindowF,
// } from "@react-google-maps/api";
// import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
// import { Box } from "@mui/material";
// // import CustomMarker from "./CustomMarker";

// const containerStyle = {
//   //   width: "400px",
//   height: "400px",
// };

// const defaultPosition = {
//   lng: -74.006,
//   lat: 40.7128,
// };

// const CustomMarker = ({ marker }) => {
//   const [showInfoWindow, setShowInfoWindow] = useState(false);
//   const position = {
//     lat: marker?.lat,
//     lng: marker?.lon,
//   };
//   return (
//     <MarkerF position={position} onClick={() => setShowInfoWindow((p) => !p)}>
//       {showInfoWindow && (
//         <InfoWindowF
//           position={position}
//           onCloseClick={() => setShowInfoWindow(false)}
//         >
//           <Box>
//             {venueAttributes.map((att, i) => {
//               return (
//                 <Attribute key={"venue" + i}>
//                   <AttributeLabel>{att.label}</AttributeLabel>
//                   <AttributeValue>{marker[att.name]}</AttributeValue>
//                 </Attribute>
//               );
//             })}
//           </Box>
//         </InfoWindowF>
//       )}
//     </MarkerF>
//   );
// };

// const MyGoogleMap = ({ markers }) => {
//   const [center, setCenter] = useState(null);
//   useEffect(() => {
//     if (navigator.geolocation) {
//       navigator.geolocation.getCurrentPosition(
//         (position) => {
//           const currentPosition = {
//             lat: position.coords.latitude,
//             lng: position.coords.longitude,
//           };
//           setCenter(currentPosition);
//         },
//         () => {
//           // If there's an error or the user denies location access, use the default position
//           setCenter(defaultPosition);
//         }
//       );
//     } else {
//       // Geolocation is not supported by this browser
//       setCenter(defaultPosition);
//     }
//   }, []);
//   return (
//     <LoadScript googleMapsApiKey={"AIzaSyAUZfNzdT1y4hOzpM2UwYf82QV60D_btT4"}>
//       <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
//         <MarkerClustererF>
//           {(clusterer) =>
//             markers.map((location, i) => (
//               <CustomMarker key={i} marker={location} clusterer={clusterer} />
//             ))
//           }
//         </MarkerClustererF>
//         {/* {markers &&
//           markers.length > 0 &&
//           markers?.map((m) => {
//             return (
//               <>
//                 <CustomMarker key={m.lat + ""} marker={m} />
//               </>
//             );
//           })} */}
//       </GoogleMap>
//     </LoadScript>
//   );
// };

// export default MyGoogleMap;

import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import "./Map.css";
import { createRoot } from "react-dom/client";
import PlaceIcon from "@mui/icons-material/Place";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";
import { Box } from "@mui/material";
import { calculateDistance } from "../../../utils/utility";
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const defaultPosition = {
  lng: -74.006,
  lat: 40.7128,
};
const venueAttributes = [
  {
    label: "Name",
    name: "venue_name",
  },
  {
    label: "Address",
    name: "address1",
  },
  {
    label: "City",
    name: "venue_city",
  },
  {
    label: "State",
    name: "venue_state",
  },
  {
    label: "Zip Code",
    name: "VENUE_ZIP",
  },
];
const Marker = ({ onClick, children, feature }) => {
  const _onClick = () => {
    onClick(feature);
  };
  return (
    <PlaceIcon sx={{ color: "red" }} color="red" onClick={_onClick}>
      {children}
    </PlaceIcon>
  );
};
const MyGoogleMap = ({ markers, centerProp }) => {
  const mapContainerRef = useRef(null);
  const [center, setCenter] = useState(centerProp || defaultPosition);
  const [selectedMarker, setSelectedMarker] = useState(markers[0]);
  const markerClicked = (e) => {
    setSelectedMarker(e);
  };
  // Initialize map when component mounts
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: "mapbox://styles/mapbox/streets-v11",
      center: { lng: selectedMarker?.lon, lat: selectedMarker?.lat }, // Delibrately changing to venues lon lat
      zoom: 20,
    });
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const currentPosition = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
          // map.setCenter({ lng: currentPosition.lng, lat: currentPosition.lat });
          setCenter(currentPosition);
        },
        () => {
          // If there's an error or the user denies location access, use the default position
          setCenter(defaultPosition);
        }
      );
    } else {
      // Geolocation is not supported by this browser
      setCenter(defaultPosition);
    }

    markers.forEach((feature) => {
      // Create a React ref
      const ref = React.createRef();
      // Create a new DOM node and save it to the React ref
      ref.current = document.createElement("div");
      // Render a Marker Component on our new DOM node
      createRoot(ref.current).render(
        <Marker onClick={markerClicked} feature={feature} />
      );

      // Create a Mapbox Marker at our new DOM node
      new mapboxgl.Marker(ref.current)
        .setLngLat({ lng: feature.lon, lat: feature.lat })
        .addTo(map);
    });

    // Add navigation control (the +/- zoom buttons)
    map.addControl(new mapboxgl.NavigationControl(), "top-right");

    // Clean up on unmount
    return () => map.remove();
  }, []);

  return (
    <div>
      <Box>
        <Attribute>
          <AttributeLabel>Your Coordinates</AttributeLabel>
          <AttributeValue>
            {`lng: ${center.lng}, lat: ${center.lat}`}
          </AttributeValue>
        </Attribute>
        <Attribute>
          <AttributeLabel>Selected Venue</AttributeLabel>
          <AttributeValue>
            {`lng: ${selectedMarker?.lon}, lat: ${selectedMarker?.lat}`}
          </AttributeValue>
        </Attribute>
        <Attribute>
          <AttributeLabel>Straight Line Distance</AttributeLabel>
          <AttributeValue>
            {calculateDistance(
              center?.lat,
              center?.lng,
              selectedMarker.lat,
              selectedMarker.lon
            ) + " km"}
          </AttributeValue>
        </Attribute>
      </Box>
      <div className="map-container" ref={mapContainerRef} />
    </div>
  );
};

export default MyGoogleMap;
