import React, { useCallback, useEffect, useState } from "react";
import { TextField, MenuItem, IconButton, Button, Box } from "@mui/material";
import {
  RemoveCircleOutline,
  Facebook,
  Instagram,
  YouTube,
  Twitter,
  Web,
  MusicNote,
} from "@mui/icons-material";

const socialMediaOptions = [
  { name: "Facebook", icon: <Facebook /> },
  { name: "Instagram", icon: <Instagram /> },
  { name: "YouTube", icon: <YouTube /> },
  { name: "Twitter", icon: <Twitter /> },
  { name: "Website", icon: <Web /> },
  {
    name: "Spotify",
    icon: (
      <img
        style={{ height: "24px", width: "24px" }}
        src="./logos/spotify-48.png"
      />
    ),
  },
  { name: "Apple Music", icon: <MusicNote /> },
  { name: "Google Music", icon: <MusicNote /> },
  { name: "Soundcloud", icon: <MusicNote /> },
  { name: "iHeart Radio", icon: <MusicNote /> },
  { name: "Bandcamp", icon: <MusicNote /> },
  { name: "ReverbNation", icon: <MusicNote /> },
];
// Currently icons are not being used from above instead we are using logo images
function SocialMediaField({ options, handleRemove, onChange, value }) {
  return (
    <Box sx={{ mt: 2 }} display="flex" alignItems="center" gap={2}>
      <TextField
        select
        value={value.linkname}
        onChange={(e) => onChange(e.target.value, "linkname")}
        style={{ width: 320 }}
      >
        {options.map((option) => (
          <MenuItem key={option.name} value={option.name}>
            <Box sx={{ display: "flex", gap: 2 }}>
              <img
                style={{ height: "24px", width: "24px" }}
                src={`/logos/${option.name
                  .toLowerCase()
                  .replace(/\s+/g, "-")}-48.png`}
                alt={option.name}
              />
              {option.name}
            </Box>
          </MenuItem>
        ))}
      </TextField>
      <TextField
        sx={{ width: { sm: "50%", xs: "100%" } }}
        value={value.url || value.value}
        onChange={(e) => onChange(e.target.value, "url")}
        placeholder="Enter URL"
      />
      <IconButton color="red" onClick={handleRemove}>
        <RemoveCircleOutline style={{ color: "red" }} />
      </IconButton>
    </Box>
  );
}

function SocialMediaLinkAdder({ initialLinks = [], onLinksChange }) {
  const [links, setLinks] = useState([]);
  useEffect(() => {
    setLinks(initialLinks);
  }, [initialLinks]);
  const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };
  const handleAddLink = () => {
    const updatedLinks = [...links, { linkname: "", url: "" }];
    setLinks(updatedLinks);
    onLinksChange(updatedLinks);
  };

  const handleRemoveLink = (index) => {
    const newLinks = links.filter((_, i) => i !== index);
    setLinks(newLinks);
    onLinksChange(newLinks); // Update parent when link is removed
  };
  const debouncedUpdateLinks = useCallback(
    debounce((newLinks) => {
      onLinksChange(newLinks);
    }, 500),
    []
  );

  const handleChange = (newValue, index, field) => {
    const newLinks = [...links];
    newLinks[index][field] = newValue;
    setLinks(newLinks);
    debouncedUpdateLinks(newLinks); // Debounced update when typing
  };

  return (
    <div>
      {links.map((link, index) => (
        <SocialMediaField
          key={index}
          options={socialMediaOptions.filter(
            (o) =>
              !links.map((l) => l.linkname).includes(o.name) ||
              o.name === link.linkname
          )}
          handleRemove={() => handleRemoveLink(index)}
          onChange={(newValue, field) => handleChange(newValue, index, field)}
          value={link}
        />
      ))}
      <Button sx={{ mt: 2 }} variant="contained" onClick={handleAddLink}>
        {links?.length > 0 ? "Add More Links" : "Add Links"}
      </Button>
    </div>
  );
}

export default SocialMediaLinkAdder;
