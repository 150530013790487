import React, { useEffect } from "react";
import {

  FormControl,
  TextField,
} from "@mui/material";

function DateFilter({ onChange, value="" }) {
 

  return (
    <div>
      <FormControl sx={{ mb: 2 }} component="fieldset">

      </FormControl>
        <TextField
          fullWidth
          label="Select Date"
          type="date"
          value={value}
          onChange={(event) => onChange([event.target.value])}
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ mb: 2 }}
        />
      

      
    </div>
  );
}

export default DateFilter;
