import { Link } from "react-router-dom";
import { useAppState } from "../appContext";
import React from "react";
import moment from "moment";
import firebase from "firebase/compat/app";
import parse from "html-react-parser";

export const calculateDistance = (lat1, lon1, lat2, lon2) => {
  // Radius of the Earth in kilometers
  const R = 6371;

  // Convert degrees to radians
  const rad = (deg) => (deg * Math.PI) / 180;

  // Latitude and longitude differences
  const dLat = rad(lat2 - lat1);
  const dLon = rad(lon2 - lon1);

  // Haversine formula
  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(lat1)) *
      Math.cos(rad(lat2)) *
      Math.sin(dLon / 2) *
      Math.sin(dLon / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = R * c; // Distance in kilometers

  return distance.toFixed(2);
};
export const getAttributeValue = (att, row, state) => {
  try {
    if (!state) return "-";
    const value = row[att?.name];
    switch (att?.component?.type) {
      case "dateTime":
        return value ? moment(value).format("llll") : "-";
      case "object": {
        const item = state?.[att.state].find((i) => i.id === value);
        return item?.id ? (
          <>
            <Link
              to={`/${att?.state}?${att?.component?.queryParam}=${item?.id}`}
            >
              {item?.[att?.component?.contextProp]}
            </Link>
          </>
        ) : (
          "-"
        );
      }
      case "array":
        return (
          <>
            {value.length > 0
              ? value.map((v, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Link
                        to={`/${att?.state}?${att?.component?.queryParam}=${v?.id}`}
                        replace
                      >
                        {v?.[att?.component?.contextProp]}
                      </Link>
                      {i < value.length - 1 ? ", " : ""}
                    </React.Fragment>
                  );
                })
              : // .reduce((prev, curr) => [prev, ", ", curr])
                "-"}
          </>
        );
      case "link": {
        return row[att?.component?.identifier] ? (
          <>
            <Link
              to={`/${att?.component?.path}?${att?.component?.queryParam}=${
                row[att?.component?.identifier]
              }`}
            >
              {row?.[att?.component?.contextProp]}
            </Link>
          </>
        ) : (
          "-"
        );
      }
      case "object_array":
        return (
          <>
            {value.length > 0
              ? value.map((v, i) => {
                  return (
                    <React.Fragment key={i}>
                      <Link
                        to={`/${att?.component?.path}${
                          v?.[att?.component?.queryParam]
                        }`}
                        replace
                      >
                        {v?.[att?.component?.contextProp]}
                      </Link>
                      {i < value.length - 1 ? ", " : ""}
                    </React.Fragment>
                  );
                })
              : // .reduce((prev, curr) => [prev, ", ", curr])
                "-"}
          </>
        );
      case "html":
        return value ? parse(value) : "-";
      default:
        return value || "-";
    }
  } catch (e) {}
};
export const removeNullAndUndefined = (obj) => {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === undefined) {
      delete obj[key];
    }
  });
  return obj;
};

export const generateCleanWordsWithLimit = (str, maxLength = 30) => {
  // Common English stop words to remove
  const stopWords = new Set([
    "a",
    "an",
    "and",
    "are",
    "as",
    "at",
    "be",
    "by",
    "for",
    "from",
    "has",
    "have",
    "he",
    "in",
    "is",
    "it",
    "its",
    "of",
    "on",
    "that",
    "the",
    "to",
    "was",
    "were",
    "will",
    "with",
    "like",
    "this",
    "but",
    "or",
    "if",
    "then",
    "than",
    "so",
    "no",
    "not",
    "nor",
    "up",
    "down",
    "over",
    "under",
    "again",
    "just",
    "very",
    "yet",
    "now",
  ]);

  // Function to clean up each word by removing special characters from the start and end
  const cleanWord = (word) => word.replace(/^[^\w]+|[^\w]+$/g, "");

  // Split the string into an array of words, clean them, and filter out stop words and short words
  let filteredWords = str
    .toLowerCase()
    .split(" ")
    .map(cleanWord) // Clean special characters
    .filter((word) => word.length > 2 && !stopWords.has(word.toLowerCase()));

  // Remove duplicates by converting to a Set and back to an array
  filteredWords = [...new Set(filteredWords)];

  // If the array exceeds the max length, remove words with 3 characters first
  if (filteredWords.length > maxLength) {
    console.log("Exceeds 30 so ignoring 3 length characters from ", str);
    filteredWords = filteredWords.filter((word) => word.length > 3);
  }

  // If the array still exceeds the max length, truncate the array to the first maxLength words
  if (filteredWords.length > maxLength) {
    console.log("Exceeds 30 so getting just first 30 ", str);

    filteredWords = filteredWords.slice(0, maxLength);
  }

  return filteredWords;
};

export const convertToTimestamp = (dateString, timezoneOffset = "GMT-0400") => {
  try {
    // Create a Date object with the given timezone
    const localDate = new Date(dateString + ` ${timezoneOffset}`);

    // Convert the Date object to a UTC Date object
    const utcDate = new Date(localDate.toISOString());

    // Return the Firebase Timestamp
    return firebase.firestore.Timestamp.fromDate(utcDate);
  } catch (error) {
    console.error("Error converting to timestamp:", error);
    return null;
  }
};
export const paginate = (items, pageNumber, pageSize) => {
  const startIndex = (pageNumber - 1) * pageSize;
  const end = startIndex + pageSize;
  items = items.slice(startIndex, end);
  return items;
};
