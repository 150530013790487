import React, { useEffect } from "react";
import CustomCard from "./CustomCard";
import CardHeader from "./CardHeader";
import { CardContent, Collapse } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  root: (props) => ({
    padding: "0px",
    backgroundColor: props.color ? props.color : "#fbfbfd",
  }),
});

const Card = (props) => {
  const {
    cardTitle,
    collapsible,
    children,
    icon,
    headerColor,
    color,
    disableExpand,
    colorCode,
    onExpandChange,
    isTitleJsx,
    disabled,
  } = props;
  const classes = useStyles({ color });
  const [_expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!_expanded);
    if (onExpandChange) {
      onExpandChange(!_expanded);
    }
  };

  return (
    <CustomCard disabled={disabled}>
      <CardHeader
        isTitleJsx={isTitleJsx}
        colorCode={colorCode}
        cardTitle={cardTitle}
        handleExpandClick={
          disableExpand
            ? () => {
                //do nothing
              }
            : handleExpandClick
        }
        expanded={_expanded}
        collapsible={collapsible}
        icon={icon}
        headerColor={headerColor}
        fontColor="black"
      ></CardHeader>
      {collapsible ? (
        <Collapse
          sx={{ background: "#fbfbfd" }}
          in={_expanded}
          timeout={400}
          // unmountOnExit
        >
          <CardContent classes={classes}>{children}</CardContent>
        </Collapse>
      ) : (
        <CardContent style={{ background: "#fbfbfd" }} classes={classes}>
          {children}
        </CardContent>
      )}
    </CustomCard>
  );
};

Card.defaultProps = {
  expanded: false,
};

Card.CardHeader = CardHeader;

export default Card;
