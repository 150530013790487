import React, { useState } from "react";
import { TextField, Button, Link, Typography, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useUserAuth } from "../../context/UserAuthContext";
import { useNavigate } from "react-router-dom";
import { useAppState } from "../../appContext";
import { collection } from "firebase/firestore";
import { useFetchUser } from "../../hooks";
import LoadingButton from "../Elements/LoadingButton/LoadingButton";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "100vh", // Set minimum height to fill the entire viewport
    background: "linear-gradient(to right, #ff6e7f, #bfe9ff)", // Example gradient background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: theme.spacing(4),
  },
  formContainer: {
    backgroundColor: "#fff",
    borderRadius: theme.spacing(1),
    padding: theme.spacing(4),
    textAlign: "center",
  },
  form: {
    width: "100%",
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    marginTop: "15px !important",
  },
}));

const Login = () => {
  if (localStorage.getItem("currentUser")) {
    navigate("/dashboard");
  }
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { logIn } = useUserAuth();
  const navigate = useNavigate();
  const [state, dispatch] = useAppState();
  const [loading, setLoading] = useState(false);
  const fetchUserByEmail = useFetchUser();
  const [error, setError] = useState();

  const handleLogin = async (e) => {
    e.preventDefault();
    setError("");
    try {
      setLoading(true);

      const res = await logIn(email, password);

      const { user: data } = await fetchUserByEmail(email.toLowerCase());

      if (data === null || data === undefined) {
        setError("Error: email not found");
        setLoading(false);
        return;
      }

      if (data.isDeleted) {
        setError("User does not exist anymore");
        setLoading(false);
        return;
      }

      // Check if the user is approved
      if (!data.isApproved) {
        setError("Your account is not approved yet.");
        setLoading(false);
        return;
      }

      // If approved, dispatch user data and navigate
      dispatch({
        type: "SET_PROP",
        payload: { key: "currentUser", value: data },
      });

      localStorage.setItem("currentUser", JSON.stringify(data)); // Store user as a JSON string
      navigate("/dashboard");

    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={classes.root}>
      <div className={classes.formContainer}>
        <Typography variant="h4" gutterBottom>
          Login
        </Typography>
        <form className={classes.form} onSubmit={handleLogin}>
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            variant="outlined"
            margin="normal"
            required
            fullWidth
            id="password"
            label="Password"
            name="password"
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          {error && (
            <Typography color={"red"} variant="body2">
              {error}
            </Typography>
          )}
          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            color="primary"
            className={classes.submit}
            isLoading={loading}
          >
            Login
          </LoadingButton>
        </form>
        <Typography variant="body2">
          Don't have an account? <Link href="/signup">Sign Up</Link>
        </Typography>
      </div>
    </div>
  );
};

export default Login;
