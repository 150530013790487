import { useTheme, useMediaQuery } from "@mui/material";

/**
 * if the screen is less than or equal to 959.px
 *
 * @returns (bool)
 */
const useIsSmallScreen = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  return isSmallScreen;
};

export default useIsSmallScreen;
