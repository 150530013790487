import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  Drawer,
  //   Card,
  CardContent,
  Collapse,
  IconButton,
  TextField,
  Typography,
  Button,
  Divider,
  Paper,
  TablePagination,
} from "@mui/material";

import Card from "../../Elements/Card";
import CustomSearchBar from "../../Elements/CustomSearchBar";
import { useAppState } from "../../../appContext";
import ChecklistFilter from "../Filters/ChecklistFilter";
import ViewModuleIcon from "@mui/icons-material/ViewModule"; // Icon for card view
import ViewListIcon from "@mui/icons-material/ViewList"; // Icon for accordion view
import { useLocation, useNavigate } from "react-router-dom";
import { VenueDetails } from "./VenueDetails";
import AddIcon from "@mui/icons-material/Add";
import VenueCards from "./VenueCards";
import VenueAccordion from "./VenueAccordion";
import FilterListIcon from "@mui/icons-material/FilterList";
import { useFetchVenues } from "../../../hooks/venueHooks";
import { paginate } from "../../../utils/utility";
const ITEMS_PER_PAGE = [20, 50, 100, 200, 500];

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,

    ...(open && {
      marginLeft: 0,
    }),
  })
);
const attributes = [
  {
    label: "Venue",
    name: "venue_name",
    sortable: true,
  },
  {
    label: "Address",
    name: "address1",
  },
  {
    label: "City",
    name: "venue_city",
  },
  {
    label: "Zone",
    name: "tzone",
  },
];
export default function VenuesLayout() {
  const [open, setOpen] = useState(false);
  const [viewMode, setViewMode] = useState("accordion"); // 'cards' or 'accordion'
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const venueId = searchParams.get("venueId");
  const [expanded, setExpanded] = useState({});
  const [state, dispatch] = useAppState();
  const [searchTerm, setSearchTerm] = useState("");
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(0);
  const [itemsPerPage, setItemsPerPage] = useState(20);
  const [refreshTrigger, setRefreshTrigger] = useState(0); // Initialize refresh trigger
  const fromNavigateToDetails = location.state?.fromNavigateToDetails || false;
  const removeQueryParam = (paramToRemove) => {
    // Create a URLSearchParams object from the current search string
    const searchParams = new URLSearchParams(location.search);

    // Remove the specified query parameter
    searchParams.delete(paramToRemove);

    // Navigate to the same path but with the modified search parameters
    navigate(`${location.pathname}?${searchParams.toString()}`);
  };
  useEffect(() => {
    if (fromNavigateToDetails) setRefreshTrigger((r) => r + 1);
  }, [fromNavigateToDetails]);

  const { venues, loading, error } = useFetchVenues(refreshTrigger);
  const handleChangePage = (event, value) => {
    setCurrentPage(value);
  };
  const handleChangeItemsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const handleAddVenue = () => {
    navigate("/editVenue");
  };
  const getFilteredResults = () => {
    if (!searchTerm) return venues; // Return the full array if no search term is provided

    // Convert the searchTerm to lowercase for case-insensitive comparison
    const lowerCaseSearchTerm = searchTerm.toLowerCase().trim();

    return venues.filter((item) =>
      Object.values(item).some((value) =>
        String(value).toLowerCase().includes(lowerCaseSearchTerm)
      )
    );
  };
  return (
    <Box sx={{ display: "flex" }}>
      <Main open={open}>
        {/* Rest of your main content goes here */}
        <CustomSearchBar
          handleChange={(searchTerm) => setSearchTerm(searchTerm)}
        />
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mb: 2,
            flexDirection: { xs: "column", sm: "row" },
          }}
        >
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexDirection: { xs: "column", sm: "row" },
            }}
          >
            <Button
              onClick={handleAddVenue}
              variant="contained"
              endIcon={<AddIcon />}
            >
              Add Venue
            </Button>
          </Box>
        </Box>
        {/* Data display component will be here */}

        {/* {viewMode === "cards" ? (
          <VenueCards data={venues} attributes={attributes} />
        ) : ( */}
        <VenueAccordion
          data={paginate(getFilteredResults(), currentPage + 1, itemsPerPage)}
          loading={loading}
          attributes={attributes}
          setRefreshTrigger={() => setRefreshTrigger((r) => r + 1)}
        />
        {/* )} */}
        {getFilteredResults().length > itemsPerPage && (
          <TablePagination
            component="div"
            count={getFilteredResults().length}
            page={currentPage}
            onPageChange={handleChangePage}
            rowsPerPage={itemsPerPage}
            onRowsPerPageChange={handleChangeItemsPerPage}
            rowsPerPageOptions={ITEMS_PER_PAGE}
          />
        )}
        {getFilteredResults()?.length === 0 && !loading && (
          <Typography
            sx={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            No Data found
          </Typography>
        )}
        {venueId && (
          <VenueDetails
            setRefreshTrigger={() => setRefreshTrigger((r) => r + 1)}
            onDrawerClose={() => {
              removeQueryParam("venueId");
            }}
          />
        )}
      </Main>
    </Box>
  );
}
