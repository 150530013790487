import React, { useState, useMemo } from "react";
import {
  Box,
  TextField,
  Chip,
  Checkbox,
  List,
  ListItem,
  Pagination,
} from "@mui/material";
import { useTheme } from "@mui/styles";

const ChecklistFilter = ({ data = [], onChange, colors, selectedItems }) => {
  const [searchTerm, setSearchTerm] = useState("");
  // const [selectedItems, setSelectedItems] = useState([]);
  const [page, setPage] = useState(1);
  const itemsPerPage = 5;
  const theme = useTheme();
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleToggle = (value) => {
    const currentIndex = selectedItems.indexOf(value);
    const newChecked = [...selectedItems];

    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }

    // setSelectedItems(newChecked);
    if (onChange) {
      onChange(newChecked);
    } else console.log(newChecked);
  };

  const filteredData = useMemo(() => {
    return data?.filter((item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase())
    );
  }, [data, searchTerm]);

  const handleDeleteChip = (chipToDelete) => () => {
    handleToggle(chipToDelete);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const paginatedData = useMemo(() => {
    const start = (page - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredData?.slice(start, end);
  }, [filteredData, page]);

  return (
    <Box sx={{ width: { xs: "100%", sm: 400 }, maxWidth: "100%" }}>
      <TextField
        fullWidth
        id="search-field"
        label="Search"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ bgcolor: "#fff" }}
      />
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          gap: 0.5,
          my: 2,
          // bgcolor: "#fff",
        }}
      >
        {selectedItems.map((item) => (
          <Chip
            size="small"
            key={item}
            label={data.find((d) => d.id === item).name}
            onDelete={handleDeleteChip(item)}
            sx={{
              background: colors?.secondary
                ? colors?.secondary
                : theme?.primaryColor,
              color:
                colors?.secondary && colors?.primary ? colors?.primary : "#fff",
              "& .MuiChip-deleteIcon": {
                color:
                  colors?.secondary && colors?.primary
                    ? colors?.primary
                    : "#fff",
              },
            }}
          />
        ))}
      </Box>
      <List dense sx={{ width: "100%", maxHeight: 300, overflow: "auto" }}>
        {paginatedData.map((item) => (
          <ListItem key={item.id} dense>
            <Checkbox
              edge="start"
              sx={{ padding: "2px 0px" }}
              checked={selectedItems.indexOf(item.id) !== -1}
              tabIndex={-1}
              disableRipple
              inputProps={{
                "aria-labelledby": `checkbox-list-label-${item.id}`,
              }}
              onChange={() => handleToggle(item.id)}
            />
            {item.name}
          </ListItem>
        ))}
      </List>
      <Pagination
        count={Math.ceil(filteredData.length / itemsPerPage)}
        page={page}
        onChange={handleChangePage}
        size="small"
        sx={{ mt: 2 }}
      />
    </Box>
  );
};

export default ChecklistFilter;
