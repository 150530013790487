import React from "react";
import {
  Drawer,
  IconButton,
  Typography,
  Box,
  Divider,
  Button,
  Skeleton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

function CustomDrawer({ open, onClose, title, children, loading = false }) {
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      sx={{
        "& .MuiDrawer-paper": {
          width: { xs: "90%", md: "60%" },
          //   maxWidth:50% // 70% of the viewport width
          borderTopLeftRadius: "8px",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <Box
          sx={{
            background: "linear-gradient(to right, #307fc1, #54c6dbb3)", // gradient background
            padding: 2,
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            height: "70px", // increased height of the header
            borderTopLeftRadius: "8px", // rounded top-left corner
          }}
        >
          <Typography variant="h5" sx={{ fontWeight: "bold", color: "#fff" }}>
            {title}
          </Typography>
          <IconButton onClick={onClose}>
            <CloseIcon sx={{ color: "#fff" }} />
          </IconButton>
        </Box>
        {loading ? (
          <Box width={"100%"}>
            <Skeleton height={100} animation="wave" />
            <Skeleton height={100} animation="wave" />
            <Skeleton height={100} animation="wave" />
            <Skeleton height={100} animation="wave" />
            <Skeleton height={100} animation="wave" />
          </Box>
        ) : (
          children
        )}
      </Box>
    </Drawer>
  );
}

export default CustomDrawer;
