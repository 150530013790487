import { useLocation, useNavigate } from "react-router";
import CustomDrawer from "../../Elements/CustomDrawer";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Typography,
} from "@mui/material";

import { useCallback, useEffect, useState } from "react";
import CustomAlertDialog from "../../Elements/AlertDialogue";
import { useAppState } from "../../../appContext";
import {
  useDeleteBand,
  useFetchBandById,
  useFetchEventsByBandId,
} from "../../../hooks";
import EventAccordion from "../Events/EventAccordion";
import SocialMediaLinks from "../../SocialMediaLinks";
import { useNotification } from "../../Notification";
import { Attribute, AttributeLabel, AttributeValue } from "../../Attribute";

export const BandDetails = ({
  onDrawerClose,
  setRefreshTrigger = () => {},
}) => {
  const { deleteBand, loading: deleting } = useDeleteBand();

  const [itemToDelete, setItemToDelete] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const [state] = useAppState();
  const [showPastEvents, setShowPastEvents] = useState(false);
  const [localPagination, setLocalPagination] = useState({ skip: 0, take: 20 });
  const [currentPage, setCurrentPage] = useState(0);
  const [hasEvents, setHasEvents] = useState(false);
  const { showSuccessMessage, showWarningMessage } = useNotification();

  const bandId = searchParams.get("bandId");
  const { band, loading } = useFetchBandById(bandId);
  const {
    events,
    loading: eventsLoading,
    totalItems,
  } = useFetchEventsByBandId(bandId, showPastEvents);

  useEffect(() => {
    if (events && events.length > 0) {
      setHasEvents(true);
    } else {
      setHasEvents(false);
    }
  }, [events]);

  const handleEdit = useCallback((e, id) => {
    e.stopPropagation();
    searchParams.set("bandId", id);
    navigate(`/editBand?${searchParams.toString()}`);
  }, []);
  const handleDelete = useCallback(
    (e, item) => {
      e.stopPropagation();
      if (hasEvents) {
        showWarningMessage("Band contains Events");
        return;
      }
      setItemToDelete(item);
    },
    [hasEvents]
  );
  const handledeleteBand = () => {
    deleteBand(itemToDelete?.id).then(() => {
      handleCloseDialog();
    });
  };
  const handleCloseDialog = () => {
    setItemToDelete(null);
    searchParams.delete("bandId");

    // Navigate to the same path but with the modified search parameters
    navigate(`${location.pathname}?${searchParams.toString()}`);
    setRefreshTrigger();
  };
  const getPaginatedResults = () => {
    let searchResults = events.slice(
      localPagination.skip,
      localPagination.take + localPagination.skip
    );

    return searchResults;
  };

  return (
    <>
      <CustomDrawer
        open={bandId}
        loading={loading}
        onClose={() => {
          onDrawerClose();
        }}
        title={band?.bandname}
      >
        {band ? (
          <>
            <Box
              sx={{
                padding: 2,
                flex: 1,
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  mb: 5,
                  height: "400px",
                }}
              >
                <Box
                  component="img"
                  src={
                    band?.imageUrl
                      ? band.imageUrl
                      : "https://via.placeholder.com/400x400?text=Cover+Photo"
                  }
                  alt="Cover Photo"
                  sx={{
                    objectFit: "cover",
                  }}
                />
              </Box>

              <Typography variant="h6" sx={{ display: "block" }}>
                {band?.alias?.length ? (
                  <>
                    {"aka: "}
                    <strong>{band?.alias?.map((a) => a).join(", ")}</strong>
                  </>
                ) : (
                  ""
                )}
              </Typography>
              <Box>
                <Attribute>
                  <AttributeLabel>{"Internal Link"}</AttributeLabel>
                  <AttributeValue>{band?.internallink}</AttributeValue>
                </Attribute>
              </Box>
              <Box sx={{ display: "flex", mt: 2, justifyContent: "center" }}>
                <SocialMediaLinks size={"96"} links={band.socialMediaLinks} />
              </Box>
              <Divider sx={{ my: 2 }} />
              <h4 style={{ textDecoration: "underline", fontWeight: "bold" }}>
                Events
              </h4>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showPastEvents}
                    onChange={() => {
                      setShowPastEvents((p) => !p);
                      setCurrentPage(0);
                    }}
                  />
                }
                label="Show Past Events"
              />
              <Box sx={{ display: "flex", flexWrap: "wrap", gap: 2 }}>
                {(eventsLoading || loading || totalItems > 0) && (
                  <Box sx={{ flex: "1 0 calc(50% - 16px)" }}>
                    <EventAccordion
                      data={getPaginatedResults()}
                      showIndicators={true}
                      loading={eventsLoading || loading}
                      totalCount={totalItems}
                      showCompactDesign={true}
                      showImage={false}
                      isMainView={false}
                      setPaginationProps={(skip, take) => {
                        setLocalPagination({
                          skip,
                          take,
                        });
                      }}
                      currentPage={currentPage}
                      setCurrentPage={(v) => setCurrentPage(v)}
                    />
                  </Box>
                )}
                {events?.length === 0 && !eventsLoading && (
                  <Typography
                    sx={{
                      display: "flex",
                      ml: "45%",
                    }}
                  >
                    No Data found
                  </Typography>
                )}
              </Box>
              <Divider sx={{ my: 2 }} />
            </Box>
            <Box
              sx={{
                display: "flex",
                // justifyContent: "space-evenly",
                gap: 5,
                padding: 2,
                borderTop: "1px solid",
                borderColor: "divider",
              }}
            >
              <Button
                variant="contained"
                color="error"
                onClick={(e) => handleDelete(e, band)}
              >
                Delete
              </Button>
              <Button
                variant="outlined"
                color="primary"
                onClick={(e) => handleEdit(e, band?.id)}
              >
                Edit
              </Button>
            </Box>
          </>
        ) : (
          <CircularProgress />
        )}
      </CustomDrawer>
      <CustomAlertDialog
        title={"Delete Band Confirmation"}
        description={`Are you sure you want to delete the band ${itemToDelete?.bandname}`}
        proceed={handledeleteBand}
        open={Boolean(itemToDelete?.id)}
        isLoading={deleting}
        handleClose={handleCloseDialog}
      />
    </>
  );
};
