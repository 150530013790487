import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Container,
  TextField,
  Typography,
  Button,
  Grid,
  Box,
  Autocomplete,
  Skeleton,
} from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import { useAppState } from "../../../appContext";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { LoadingButton } from "@mui/lab";
import { useLocation, useNavigate } from "react-router";
import {
  useAddPage,
  useFetchPageById,
  useFetchPageByInternalLink,
  useUpdatePage,
} from "../../../hooks/pageHooks";
import ImageCropper from "../Events/ImageCropper";
import SocialMediaLinkAdder from "../../SocialMediaLinkAdder";
import { GeoPoint } from "firebase/firestore";
import PaginatedAutocomplete from "../../Elements/PaginatedAutocomplete";
import { generateCleanWordsWithLimit } from "../../../utils/utility";
import { auth } from "../../../firebase-config.";

const PageForm = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const pageId = searchParams.get("pageId");
  const [state, dispatch] = useAppState();

  const { page: pageData, loading: pageDataLoading } = useFetchPageById(pageId);
  const [isEditMode, setIsEditMode] = useState(false);

  const [guid, setGuid] = useState(uuidv4());
  const [pageName, setPageName] = useState("");
  const [longitude, setLongitude] = useState("");
  const [latitude, setLatitude] = useState("");
  const [description, setDescription] = useState("");
  const [imageUrl, setImageUrl] = useState("");
  const [image, setImage] = useState(null);
  const [internalLink, setInternalLink] = useState("");
  const [radius, setRadius] = useState("");

  const { addPage, loading: addingPage } = useAddPage();
  const { updatePage, loading: updatingPage } = useUpdatePage();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchPage, loading: pageLoading } = useFetchPageByInternalLink();

  const [errors, setErrors] = useState({
    pageName: "",
    latitude: "",
    longitude: "",
    internalLink: "",
    radius: "",
  });
  const removeSlashes = (str) => {
    if (!str) return null;
    if (str.startsWith("/")) {
      str = str.slice(1);
    }
    if (str.endsWith("/")) {
      str = str.slice(0, -1);
    }
    return str;
  };

  useEffect(() => {
    if (pageData) {
      setIsEditMode(true);
      setGuid(pageData.id);
      setPageName(pageData.page_name);
      setLongitude(pageData.lon);
      setLatitude(pageData.lat);
      setDescription(pageData.description || "");
      setImageUrl(pageData.imageUrl || "");
      setInternalLink(removeSlashes(pageData.internallink) || "");
      setRadius(pageData.radius || "");
    }
  }, [pageData]);

  const validate = () => {
    let temp = {};
    temp.pageName = pageName ? "" : "This field is required.";

    temp.latitude = latitude ? "" : "This field is required.";
    temp.longitude = longitude ? "" : "This field is required.";
    temp.radius = radius ? "" : "This field is required.";

    temp.internalLink = internalLink
      ? /^[a-zA-Z0-9-]+$/.test(internalLink)
        ? ""
        : "Only alphanumeric characters and hyphens are allowed."
      : "This field is required.";
    setErrors(temp);

    return Object.values(temp).every((x) => x === "");
  };

  const handleImageCrop = (img, croppedDataUrl) => {
    if (!img || !croppedDataUrl) {
      setImage("");
      return;
    }
    setImage(img);
    setImageUrl(croppedDataUrl);
  };

  const handleImageUpload = async (imageFile) => {
    if (!imageFile) {
      return imageUrl || "";
    }
    const storage = getStorage();
    const storageRef = ref(storage, "pages/" + pageName.replaceAll(" ", "-"));

    try {
      const snapshot = await uploadBytes(storageRef, imageFile);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    } catch (error) {
      console.error("Error uploading image: ", error);
      return "";
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (validate()) {
      setIsLoading(true);

      const finalImageUrl = image ? await handleImageUpload(image) : imageUrl;

      const formData = {
        id: guid,
        title: pageName,

        description,
        lon: longitude,
        lat: latitude,
        modifieddate: dayjs().toISOString(),
        imageUrl: finalImageUrl,
        miles: radius,
        coordinates: new GeoPoint(parseFloat(latitude), parseFloat(longitude)),
        searchTerms: generateCleanWordsWithLimit(pageName.toLowerCase()),
        title_lowercase: pageName.toLowerCase(),
        internallink: `/${internalLink.toLowerCase()}/`,
      };
      fetchPage(internalLink).then(async (res) => {
        if (isEditMode) {
          if (res?.id && res?.id !== pageData.id) {
            let err = {
              ...errors,
              internalLink: `${internalLink} already exists`,
            };
            setErrors(err);
          } else {
            await updatePage(guid, formData);
            navigateToDetails(guid);
          }
        } else {
          if (res?.id) {
            let err = {
              ...errors,
              internalLink: `${internalLink} already exists`,
            };
            setErrors(err);
          } else {
            await addPage({ ...formData, createdAt: dayjs().toISOString() });

            navigateToDetails(guid);
          }
        }
      });

      setIsLoading(false);
    }
  };

  const navigateToDetails = (id) => {
    navigate("/pages");
  };
  if (pageDataLoading) {
    return (
      <>
        <Box width={"100%"}>
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
          <Skeleton height={100} animation="wave" />
        </Box>
      </>
    );
  }

  return (
    <Container maxWidth={false}>
      <Card
        raised
        sx={{ margin: "auto", maxWidth: "calc(100% - 32px)", boxShadow: 3 }}
      >
        <Box sx={{ bgcolor: "#307fc1", color: "white", p: 2 }}>
          <Typography variant="h6">
            {isEditMode ? "Edit Page" : "Add Page"}
          </Typography>
        </Box>
        <CardContent>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <form onSubmit={handleSubmit} noValidate>
              <ImageCropper
                src={isEditMode ? imageUrl : ""}
                onCrop={handleImageCrop}
                aspectRatio={null}
              />

              {/* <Typography variant="subtitle1" sx={{ mt: 6, color: "gray" }}>
                ID: {guid}
              </Typography> */}

              <TextField
                fullWidth
                label="Page Name/Title"
                value={pageName}
                onChange={(e) => setPageName(e.target.value)}
                error={!!errors.pageName}
                helperText={errors.pageName || ""}
                sx={{ marginTop: 8 }}
                required
              />

              <Grid container spacing={3}>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Latitude (40.728...)"
                    value={latitude}
                    onChange={(e) => setLatitude(e.target.value)}
                    error={!!errors.latitude}
                    helperText={errors.latitude || ""}
                    sx={{ my: 2 }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Longitude (-73.999...)"
                    value={longitude}
                    onChange={(e) => setLongitude(e.target.value)}
                    error={!!errors.longitude}
                    helperText={errors.longitude || ""}
                    sx={{ my: 2 }}
                    required
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Radius (miles)"
                    value={radius}
                    onChange={(e) => setRadius(e.target.value)}
                    error={!!errors.radius}
                    helperText={errors.radius || ""}
                    required
                    type="number"
                    sx={{ my: 2 }}
                  />
                </Grid>
                <Grid item xs={12} sm={3}>
                  <TextField
                    fullWidth
                    label="Internal Link"
                    value={internalLink}
                    onChange={(e) => setInternalLink(e.target.value)}
                    error={!!errors.internalLink}
                    helperText={errors.internalLink || ""}
                    required
                    sx={{ my: 2 }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} sm={12} sx={{ pb: 1 }}>
                <Typography variant="body1" sx={{ pt: 1 }}>
                  To get latitude and longitude: Open the{" "}
                  <a
                    href="https://www.google.com/maps/search/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Google Maps
                  </a>{" "}
                  and right-click on the pin and select the coordinates.
                  <br></br>
                  Page Internal Link will the url. If you type{" "}
                  <strong>annapoils</strong>, url will be
                  https://www.webtunes.com/<strong>annapoils</strong>
                </Typography>
              </Grid>

              <TextField
                fullWidth
                label="Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                multiline
                rows={4}
                sx={{ my: 2 }}
              />
              <Box sx={{ display: "flex", justifyContent: "end", gap: 2 }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => navigateToDetails(null)}
                >
                  Cancel
                </Button>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={addingPage || updatingPage || isLoading}
                >
                  Submit
                </LoadingButton>
              </Box>
            </form>
          </LocalizationProvider>
        </CardContent>
      </Card>
    </Container>
  );
};

export default PageForm;
