import { Navigate } from "react-router-dom";
import { useUserAuth } from "../context/UserAuthContext";
import ResponsiveAppBar from "./Navbar/ResponsiveAppBar";

const pages_manager = [
  { title: "Dashboard", route: "/dashboard" },
  { title: "Events", route: "/events" },
  { title: "Venues", route: "/venues" },
  { title: "Bands", route: "/bands" },
  { title: "Pages", route: "/pages" },
];

const ProtectedRoute = (props) => {
  // const currentRole = localStorage.getItem("role");
  const { children, role } = props;
  const { user } = useUserAuth();

  // if (!user || currentRole !== role) {
  if (!user) {
    localStorage.clear();
    return <Navigate to="/" />;
  }
  return (
    <>
      <ResponsiveAppBar pages={pages_manager} />
      <div style={{ marginTop: "69px" }}>{children}</div>
    </>
  );
};

export default ProtectedRoute;
